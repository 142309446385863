import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	InputAdornment,
	InputBase,
	List,
	ListSubheader,
	Theme,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo, useRef, useState } from 'react';
import { IoClose as CloseIcon, IoSearch as SearchIcon } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { FetchError, ParticipantListing } from '../types';
import ParticipantListItem from './ParticipantListing';

const useStyles = makeStyles((theme: Theme) => ({
	list: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '24ch',
		maxWidth: '24ch',
		borderRight: `1px solid ${theme.palette.divider}`,
		overflow: 'hidden',
		flex: 1,
		paddingBottom: 0,
	},
	listHeader: {
		margin: theme.spacing(0, 1),
	},
	checkbox: {},
	searchIcon: {
		padding: theme.spacing(0, 1, 0, 2),
		pointerEvents: 'none',
		color: theme.palette.grey[400],
	},
	closeButton: {
		margin: theme.spacing(0, 1),
	},
	closeIcon: {
		color: theme.palette.grey[400],
	},
	inputRoot: {
		color: 'inherit',
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
	},
	listMessage: {
		padding: theme.spacing(0, 2),
		color: theme.palette.grey[500],
		textAlign: 'center',
	},
	divider: {
		marginTop: theme.spacing(2),
	},
	listContents: { overflow: 'auto', flex: 1 },
	listFooter: {
		display: 'flex',
		margin: theme.spacing(1, 1, 0),
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	participantCount: {
		padding: theme.spacing(1),
	},

	link: {
		color: theme.palette.primary.main,
		// fontSize: theme.mixins.
		textDecoration: 'none',
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}));

function ListMessage(props: { message: string }) {
	const classes = useStyles();

	return (
		<Typography variant="overline" className={classes.listMessage}>
			{props.message}
		</Typography>
	);
}

function participantNumber(participantId: string): number {
	return Number.parseInt(participantId.slice(participantId.indexOf('-') + 1));
}

interface ParticipantListProps {
	syncing?: boolean;
	participants: ParticipantListing[] | undefined;
	onDoUpdate?: () => void;
	error?: FetchError;
	recruitmentFormUrl?: string;
}

export default function ParticipantList({
	syncing,
	participants,
	onDoUpdate: onClick,
	error,
	recruitmentFormUrl,
}: ParticipantListProps) {
	const classes = useStyles();

	const searchRef = useRef<HTMLInputElement>(null);
	const [searchText, setSearchText] = useState<string>('');
	const [showFinalized, setShowFinalized] = useState(false);
	const [onlyIssues, setOnlyIssues] = useState(false);

	const { participantId: selectedParticipant } =
		useParams<{
			participantId: string;
		}>();

	const filteredParticipants = useMemo(() => {
		const searchTextFilter = function filterBySearch(p: ParticipantListing) {
			const safeSearchText = searchText.toLowerCase().replaceAll(/(?:[^\w\d-])/g, '');
			return !safeSearchText.length || p.id.toLowerCase().match(safeSearchText) !== null;
		};

		return participants?.filter(searchTextFilter).filter((p) => {
			if (onlyIssues && !p.issue_severity) return false;
			if (!p.completed && !p.finalized) return true;
			if (p.finalized) return showFinalized;
			return true;
		});
	}, [participants, searchText, showFinalized, onlyIssues]);

	const content = React.useMemo(() => {
		if (error) {
			switch (true) {
				case error.status === -1:
					return <ListMessage message="Network unavailable" />;
				case error.status === 500:
					return <ListMessage message="Server error" />;
				case error.status === 403:
					return <ListMessage message="Access restricted" />;
				case error.status === 401:
					return <ListMessage message="Not signed in" />;
				default:
					return <ListMessage message="Cannot retrieve participants" />;
			}
		}

		if (!filteredParticipants) {
			return <ListMessage message="Loading..." />;
		}

		if ((filteredParticipants?.length ?? 0) === 0) {
			const message = participants?.length
				? 'No matches, adjust filter'
				: 'No participants';
			return <ListMessage message={message} />;
		}

		return filteredParticipants
			.sort((a, b) => {
				return participantNumber(b.id) - participantNumber(a.id);
			})
			.map((p) => (
				<ParticipantListItem
					key={p.id}
					{...p}
					selected={p.id === selectedParticipant}
				/>
			));
	}, [error, filteredParticipants, participants, selectedParticipant]);

	const footer = useMemo(() => {
		if (!participants || !filteredParticipants) return null;

		return `${
			filteredParticipants.length !== participants.length
				? `Showing ${filteredParticipants.length} of `
				: ''
		}${participants.length} participants`;
	}, [filteredParticipants, participants]);

	return (
		<List
			className={classes.list}
			subheader={<ListSubheader>Participants</ListSubheader>}
		>
			<Box className={classes.listHeader}>
				<FormControlLabel
					control={
						<Checkbox
							checked={showFinalized}
							onChange={() => setShowFinalized(!showFinalized)}
							name="showFinalized"
							color="primary"
						/>
					}
					label="Show Finalized"
					className={classes.checkbox}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={onlyIssues}
							onChange={() => setOnlyIssues(!onlyIssues)}
							name="onlyIssues"
							color="primary"
						/>
					}
					label="Only Issues"
					className={classes.checkbox}
				/>

				<InputBase
					inputRef={searchRef}
					placeholder="Search…"
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					disabled={!participants?.length}
					inputProps={{ 'aria-label': 'search' }}
					startAdornment={
						<InputAdornment position="start">
							<SearchIcon className={classes.searchIcon} />
						</InputAdornment>
					}
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					endAdornment={
						<InputAdornment position="end" className={classes.closeButton}>
							<IconButton
								size="small"
								onClick={() => {
									setSearchText('');
									searchRef.current!.focus();
								}}
								style={{
									display: searchText.length === 0 ? 'none' : undefined,
								}}
							>
								<CloseIcon className={classes.closeIcon} />
							</IconButton>
						</InputAdornment>
					}
				/>

				<Divider className={classes.divider} />
			</Box>
			<Box className={classes.listContents}>{content}</Box>
			<Divider />
			<Box display="flex" justifyContent="flex-end">
				<Typography variant="caption" className={classes.participantCount}>
					{footer}
				</Typography>
			</Box>
			<Divider />
			<Box className={classes.listFooter}>
				<Button
					variant="text"
					color="primary"
					disabled={syncing}
					onClick={onClick}
				>
					{syncing ? 'Syncing...' : 'Sync'}
				</Button>
				{/* <Button
					href={``}
					rel="noreferrer"
					target="_blank"
					variant="text"
					color="primary"
				>
					New
				</Button> */}
			</Box>
		</List>
	);
}
