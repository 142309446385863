import {
	Box,
	makeStyles,
	Theme,
	Typography,
	useTheme
} from '@material-ui/core';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime, Interval } from 'luxon';
import { Bar } from 'react-chartjs-2';

Chart.register(...registerables);

const useStyles = makeStyles<Theme, { condenseGraph?: boolean }>(() => ({
	timeline: ({ condenseGraph }) => ({
		height: condenseGraph ? '30px' : '50px',
		width: condenseGraph ? '100%' : 'auto',
		marginBottom: condenseGraph ? '-15px' : 'auto',
		marginLeft: condenseGraph ? '4px' : 'auto',
	}),
}));

interface CoverageGraphProps {
	appliedTime: DateTime | null;
	removalTime: DateTime | null;
	loggedDays: { [key: string]: boolean };
	condenseGraph?: boolean;
}
export default function CoverageGraph(props: CoverageGraphProps) {
	const theme = useTheme();
	const { appliedTime, removalTime, loggedDays, condenseGraph } = props;
	const classes = useStyles({ condenseGraph });

	if (!appliedTime || !loggedDays) {
		// logged days included because some devices may be missing logged days
		return <div className={classes.timeline} />;
	}

	let startTime: DateTime;
	let endTime: DateTime;

	if (condenseGraph) {
		endTime = DateTime.local()
		startTime = endTime.minus({ days: 13 })
	}
	else {
		startTime = appliedTime.startOf('day');
		endTime = (removalTime ?? DateTime.now()).endOf('day');
	}

	if (endTime < startTime) {
		return (
			<div className={classes.timeline}>
				<ErrorMessage theme={theme}>
					Remove time set before application time, check dates in REDCap.
				</ErrorMessage>
			</div>
		);
	}
	const interval = Array.from(days(Interval.fromDateTimes(startTime, endTime)));
	const daysInInterval = Math.ceil(endTime.diff(startTime, 'days').days);
	const datesLogged = Array(daysInInterval).fill(0);

	Object.keys(loggedDays).forEach((day) => {
		const loggedTime = DateTime.fromISO(day);
		const loggedIndex = loggedTime.diff(startTime, 'days').toObject()['days'];
		datesLogged[Math.floor(loggedIndex!)] += 1;
	});

	const removalTimeISO = removalTime?.toISODate();
	const appliedTimeISO = appliedTime?.toISODate();

	const options: ChartOptions<'bar'> = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					// @ts-ignore (fine if it's undefined)
					label: () => undefined,
				},
			},
		},
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			x: {
				type: 'timeseries' as const,
				time: {
					tooltipFormat: 'DDD',
					unit: 'day',
					round: 'day',
				},
				grid: {
					drawBorder: !condenseGraph,
					display: true,
					drawOnChartArea: condenseGraph ?? false,
					color: function (context) {
						if (!condenseGraph) {
							return 'lightgrey';
						}

						if (context.tick) {
							const tickDate = DateTime.fromMillis(context.tick?.value).toISODate();
							if (tickDate === removalTimeISO || tickDate === appliedTimeISO) {
								return 'grey';
							}
						}
						return 'white';
					},
				},
				min: startTime.toISO(),
				max: endTime.startOf('day').toISO(),
				ticks: {
					callback: (value, index, ticks) => {
						if (condenseGraph) {
							return '';
						}
						return value;
					}
				},
			},
			y: {
				display: false,
			},
		},
	};
	const data: ChartData<'bar'> = {
		labels: interval,
		datasets: [
			{
				data: datesLogged,
				backgroundColor: theme.palette.primary.main,
				barPercentage: 1.2,
				borderSkipped: condenseGraph ? false : 'top',
				borderRadius: condenseGraph ? 4 : 0,
			},
		],
	};
	return (
		<div className={classes.timeline}>
			{datesLogged.length > 0 ? <Bar options={options} data={data} /> : null}
		</div>
	);
}

function ErrorMessage(props: { theme: Theme; children: string }) {
	const { theme, children } = props;
	return (
		<Box color={theme.palette.text.secondary}>
			<Typography align="left">{children}</Typography>
		</Box>
	);
}

export function* days(interval: Interval) {
	let cursor = interval.start.startOf('day');
	while (cursor < interval.end) {
		yield cursor;
		cursor = cursor.plus({ days: 1 });
	}
}
