import {
	AuthenticateConfig,
	AuthenticateSuccess,
	FetchError,
	FetchResult,
	FileRecord,
	NetworkDeviceDetails,
	ParticipantDetails,
	ParticipantListing,
	ParticipantsFetchResponse,
	StudyDetails,
	StudyListing,
	ValidityIssue
} from './types';

export const baseUri = process.env.REACT_APP_SDM_BASE_URI ?? '';
let accessToken: string | undefined;

// Auth APIs

function interceptAccessToken<
	T extends Pick<AuthenticateSuccess, 'access_token'>
>([res, err]: [null, FetchError] | [T, null]): [null, FetchError] | [T, null] {
	if (res?.access_token) {
		accessToken = res.access_token;
	}
	return [res, err] as [null, FetchError] | [T, null];
}

export const authenticate = ({
	token,
	provider,
	access_token: secret,
}: AuthenticateConfig) => {
	const [resPromise, abort] = safeFetch<AuthenticateSuccess>(
		'/v1/token/exchange',
		'POST',
		JSON.stringify({
			token,
			provider,
			secret,
		})
	);

	// Intercept the access token for use in fetches
	const snoopedTokenPromise = resPromise.then(interceptAccessToken);

	return [snoopedTokenPromise, abort] as [
		FetchResult<AuthenticateSuccess>,
		AbortController
	];
};

type RefreshSuccess = Pick<AuthenticateSuccess, 'access_token'>;
export const refreshAccessToken = (refreshToken: string) => {
	// TODO: Ensure the refresh token is not expired
	const [resPromise, abort] = safeFetch<RefreshSuccess>(
		'/v1/token/refresh',
		'POST',
		JSON.stringify({ token: refreshToken })
	);

	// Intercept the access token for use in fetches
	const snoopedTokenPromise = resPromise.then(interceptAccessToken);

	return [snoopedTokenPromise, abort] as [
		FetchResult<RefreshSuccess>,
		AbortController
	];
};

export function signOut() {
	// UI will drive the rest, but we need to be sure we don't recycle access tokens
	accessToken = undefined;
}

// Study APIs

export const fetchStudyList = () => safeFetch<StudyListing[]>('/v1/studies');

export const fetchStudyDetails = (studyId: string) =>
	safeFetch<StudyDetails>(`/v1/studies/${studyId}`);

export const fetchStudyParticipants = (studyId: string, status: string = "") =>
	safeFetch<ParticipantsFetchResponse>(`/v1/studies/${studyId}/participants?status=${status}`);

export const syncStudyParticipants = (studyId: string) =>
	safeFetch<ParticipantListing[]>(`/v1/studies/${studyId}/sync`, 'POST');

export const fetchParticipantDetails = (
	studyId: string,
	participantId: string
) => safeFetch<ParticipantDetails>(`/v1/studies/${studyId}/${participantId}`);

// const deviceObservables: {
// 	[id: string]: Subject<NetworkDeviceDetails<any>>;
// } = {};
//
// export function watchDeviceDetails(config: {
// 	studyId: string,
// 	participant: string,
// 	deviceType: string}): Observable<NetworkDeviceDetails> {
// 		deviceObservables[`${config.studyId}-${config.participant}-${config.deviceType}`];
// 		return null;
// 	}

export const fetchDeviceDetails = (
	studyId: string,
	participantId: string,
	deviceType: string
) =>
	safeFetch<NetworkDeviceDetails>(
		`/v1/studies/${studyId}/${participantId}/devices/${deviceType}`
	);

interface IngestConfig {
	studyId: string;
	participantId: string;
	deviceType: string;
}

export function ingestCloudData({
	studyId,
	participantId,
	deviceType,
}: IngestConfig) {
	return safeFetch<string>(
		`/v1/studies/${studyId}/${participantId}/devices/${deviceType}`,
		'POST'
	);
}

export function reprocessFile({
	studyId,
	participantId,
	deviceType,
	rawKey
}: IngestConfig & { rawKey: string }) {
	return safeFetch<string>(
		`/v1/studies/${studyId}/${participantId}/devices/${deviceType}/retry`,
		'POST',
		JSON.stringify({rawKey})
	);
}

export function deleteFile({
	studyId,
	participantId,
	deviceType,
	rawKey
}: IngestConfig & { rawKey: string }) {
	return safeFetch<string>(
		`/v1/studies/${studyId}/${participantId}/devices/${deviceType}`,
		'DELETE',
		JSON.stringify({rawKey})
	);
}

export type ReviewResults = {
	files: FileRecord[];
	issues: ValidityIssue[];
};

export function review(studyId: string, participantId: string) {
	return safeFetch<ReviewResults>(
		`/v1/studies/${studyId}/${participantId}/review`,
		'GET'
	);
}

export function remove(studyId: string, participantId: string) {
	return safeFetch<{}>(
		`/v1/studies/${studyId}/${participantId}`,
		'DELETE'
	);
}

export function complete(studyId: string, participantId: string) {
	return safeFetch<undefined>(
		`/v1/studies/${studyId}/${participantId}/complete`,
		'POST'
	);
}

export function finalize(studyId: string, participantId: string, ignoreWarnings?: boolean) {
	const params = new URLSearchParams();
	if (ignoreWarnings) {
		params.append('ignoreWarnings', 'true');
	}

	return safeFetch<undefined>(
		`/v1/studies/${studyId}/${participantId}/finalize?${params.toString()}`,
		'POST'
	);
}

// Utils

export function getHeaders(): { [headerKey: string]: string } | undefined {
	return accessToken
		? {
				Authorization: `Bearer ${accessToken}`,
		  }
		: undefined;
}

function wrapResponse(res: Response) {
	return (body: any) => [res.ok, res.status, body] as const;
}

function safeFetch<T>(
	input: RequestInfo,
	method: 'GET' | 'POST' | 'DELETE' = 'GET',
	body?: BodyInit
): [FetchResult<T>, AbortController] {
	const abortController = new AbortController();
	const fetchParams: RequestInit = {
		signal: abortController.signal,
		method,
		body,
	};
	if (accessToken) {
		fetchParams.headers = getHeaders();
	}
	const fetchPromise = fetch(`${baseUri}${input}`, fetchParams)
		.then<readonly [boolean, number, any]>((res) => {
			const isJson = res.headers
				.get('Content-Type')
				?.startsWith('application/json');

			return (isJson ? res.json() : res.text()).then(wrapResponse(res));
		})
		.then(([ok, status, body]) => {
			if (ok) return [body as T, null] as [T, null];

			console.error('Issue with request', input, status, body);
			return [null, { status, error: body }] as [null, FetchError];
		})
		.catch((e) => {
			console.error('Network failure', e);
			return [null, { status: -1, error: e }] as [null, FetchError];
		});

	return [fetchPromise, abortController];
}

export function parseJwt(token: string): { exp: number; iss: string } {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}
