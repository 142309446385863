import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './useAuth';

export function useBailout() {
	const { access_token } = useAuth();
	const location = useLocation();

	if (!access_token) {
		return (
			<Navigate
				to={{
					pathname: '/login',
				}}
				state={{ from: location.pathname }}
			/>
		);
	}
}
