import { Container, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { fetchStudyList } from '../../api';
import { useBailout } from '../../hooks/useBailout';
import { FetchError, StudyListing } from '../../types';
import StudyList from '../StudyList';

export default function StudyListScreen() {
	const [studies, setStudies] = useState<StudyListing[]>();
	const [error, setError] = useState<FetchError | undefined>();

	const [studyFetchIter, setStudyFetchIter] = useState(0);

	useEffect(
		function loadStudies() {
			const [promise, abortController] = fetchStudyList();
			promise.then(([payload, error]) => {
				if (!error) {
					setStudies(payload!);
					setError(undefined);
				} else {
					setError(error);
				}
			});

			return () => {
				abortController.abort();
			};
		},
		[studyFetchIter]
	);

	const theme = useTheme();

	const bailout = useBailout();
	if (bailout) return bailout;

	return (
		<Container maxWidth="lg" style={{ marginTop: theme.spacing(2) }}>
			<Typography variant="h2">Studies</Typography>
			<StudyList
				{...{ studies, error }}
				onReload={() => setStudyFetchIter(studyFetchIter + 1)}
			/>
		</Container>
	);
}
