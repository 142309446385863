import {
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { FetchError, ParticipantListing } from '../types';
import ParticipantCard from './ParticipantCard';

const useStyles = makeStyles((theme: Theme) => ({
	listMessage: {
		padding: theme.spacing(0, 2),
		color: theme.palette.grey[500],
		textAlign: 'center',
	},
	panel: {
		display: 'flex',
		padding: theme.spacing(2, 0),
		alignItems: 'end',
		borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
	},
	toolbar: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
	},
	searchBar: {
		flex: 1,
		maxHeight: 300,
		marginRight: theme.spacing(4),
	},
	list: {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '100%',
	},
	listItem: {
		display: 'flex',
		flex: 1,
	}
}));

function ListMessage(props: { message: string }) {
	const classes = useStyles();

	return (
		<Typography variant="overline" className={classes.listMessage}>
			{props.message}
		</Typography>
	);
}

function participantNumber(participantId: string): number {
	return Number.parseInt(participantId.slice(participantId.indexOf('-') + 1));
}

interface ParticipantListProps {
	participants: ParticipantListing[] | undefined;
	error?: FetchError;
	includeFinalized: boolean;
	onChangeFilter(includeFinalized: boolean): void;
}

export default function ParticipantSummaryList({
	participants,
	error,
	includeFinalized: showFinalized,
	onChangeFilter,
}: ParticipantListProps) {
	const classes = useStyles();

	const [searchText, setSearchText] = useState<string[]>([]);
	const [onlyIssues, setOnlyIssues] = useState(false);

	const filteredParticipants = useMemo(() => {
		const searchTextFilter = function filterBySearch(p: ParticipantListing) {
			return !searchText.length || searchText.includes(p.id)
		};

		return participants?.filter(searchTextFilter).filter((p) => {
			if (onlyIssues && !p.issue_severity) return false;
			if (!p.completed && !p.finalized) return true;
			if (p.finalized) return showFinalized;
			return true;
		});
	}, [participants, searchText, showFinalized, onlyIssues]);

	const filteredOptions = useMemo(() => {
		return participants?.filter((p) => {
			if (onlyIssues && !p.issue_severity) return false;
			if (!p.completed && !p.finalized) return true;
			if (p.finalized) return showFinalized;
			return true;
		}).sort((a, b) => {
			return participantNumber(b.id) - participantNumber(a.id);
		});
	}, [participants, showFinalized, onlyIssues])

	const content = React.useMemo(() => {
		if (error) {
			switch (true) {
				case error.status === -1:
					return <ListMessage message="Network unavailable" />;
				case error.status === 500:
					return <ListMessage message="Server error" />;
				case error.status === 403:
					return <ListMessage message="Access restricted" />;
				case error.status === 401:
					return <ListMessage message="Not signed in" />;
				default:
					return <ListMessage message="Cannot retrieve participants" />;
			}
		}

		if (!filteredParticipants) {
			return <ListMessage message="Loading participants, please wait..." />;
		}

		if ((filteredParticipants?.length ?? 0) === 0) {
			const message = participants?.length
				? 'No matches, adjust filter'
				: 'No participants';
			return <ListMessage message={message} />;
		}

		return filteredParticipants
			.sort((a, b) => {
				const aModified = a.last_upload;
				const bModified = b.last_upload;
				if (aModified) {
					if (bModified) {
						return bModified.localeCompare(aModified);
					}
					return 1;
				} else if (bModified) {
					return -1;
				}
				return participantNumber(b.id) - participantNumber(a.id);
			})
			.map((p) => (
				<Grid item xs={12} sm={6} md={4} key={p.id} className={classes.listItem}>
					<ParticipantCard {...p} />
				</Grid>
			));
	}, [error, filteredParticipants, participants, classes]);

	return (
		<>
			<Container maxWidth="md" className={classes.toolbar}>
				<Autocomplete
					autoComplete
					autoHighlight
					className={classes.searchBar}
					disableCloseOnSelect
					filterSelectedOptions
					multiple
					onChange={(event, newInputValue) => {
						setSearchText(newInputValue);
					}}
					options={filteredOptions? filteredOptions.map((option) => option.id) : []}
					renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Search" />}
					size="small"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={showFinalized}
							onChange={() => onChangeFilter(!showFinalized)}
							name="showFinalized"
							color="primary"
						/>
					}
					label="Show Finalized"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={onlyIssues}
							onChange={() => setOnlyIssues(!onlyIssues)}
							name="onlyIssues"
							color="primary"
						/>
					}
					label="Only Issues"
				/>
			</Container>
			<Box className={classes.panel}>
				<Container>
					<Grid container className={classes.list}>
						{content}
					</Grid>
				</Container>
			</Box>
		</>
	);
}
