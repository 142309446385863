import { ThemeProvider } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
	useLocation
} from 'react-router-dom';
import { parseJwt, refreshAccessToken, signOut } from './api';
import './App.scss';
import Header from './components/Header';
import LoginScreen from './components/screens/LoginScreen';
import StudyListScreen from './components/screens/StudyListScreen';
import StudyScreen from './components/screens/StudyScreen';
import theme from './components/theme';
import useAuth from './hooks/useAuth';
import useSignInRedirects from './hooks/useSignInRedirect';
import './index.css';
import { AuthContext, AuthenticateSuccess } from './types';

const REFRESH_TOKEN_KEY = 'sdm_refresh_token';

function App() {
	const [tokens, setTokens] = useState<AuthenticateSuccess>();

	// TODO: move token handling to an auth module or the API
	const handleSignIn = useCallback(function (credentials: AuthenticateSuccess) {
		setTokens(credentials);
		localStorage.setItem(REFRESH_TOKEN_KEY, credentials.refresh_token);
		// TODO Make it so that refresh the page returns to original URL following auth
	}, []);

	const handleSignOut = useCallback(function () {
		signOut();
		setTokens(undefined);
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}, []);

	useEffect(
		function refreshAccessCode() {
			// Exchange a refresh token for a new access token when the current access token nears expiry
			const { refresh_token, access_token } = tokens ?? {};
			if (!access_token || !refresh_token) return;

			const exp = parseJwt(access_token).exp - 60; // refresh a minute early to avoid race conditions
			const expDT = DateTime.fromSeconds(exp);

			const cancelId = setTimeout(() => {
				refreshAccessToken(refresh_token)[0].then(([res, err]) => {
					if (!err) {
						handleSignIn({
							access_token: res!.access_token,
							refresh_token: refresh_token!,
						});
					}
				});
			}, expDT.diffNow().milliseconds); // refresh early to avoid race condition
			return () => {
				clearTimeout(cancelId);
			};
		},
		[tokens, handleSignIn]
	);

	useEffect(
		function autoSignOut() {
			// When the refresh token expires sign out the user
			if (!tokens) return;
			const exp = parseJwt(tokens.refresh_token).exp;
			const expDT = DateTime.fromSeconds(exp);
			const cancelId = setTimeout(handleSignOut, expDT.diffNow().milliseconds);
			return () => {
				clearTimeout(cancelId);
			};
		},
		[tokens, handleSignOut]
	);

	const authContext = {
		...tokens,
		onSignIn: handleSignIn,
		onSignOut: handleSignOut,
	};

	return (
		<ThemeProvider theme={theme}>
			<Router basename={process.env.PUBLIC_URL}>
				<div className="App">
					<AuthContext.Provider value={authContext}>
						<AppContents />
					</AuthContext.Provider>
				</div>
			</Router>
		</ThemeProvider>
	);
}

function AppContents() {
	// TODO: move token handling to an auth module or the API
	const auth = useContext(AuthContext);

	const signInDirector = useSignInRedirects();

	useEffect(function autoSignIn() {
		// If refresh token is available, and not expired, auto-initiate sign-in for fresh access code
		const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
		if (!refreshToken) return;
		const [refreshPromise, abortController] = refreshAccessToken(refreshToken);

		refreshPromise.then(([res, err]) => {
			if (err) {
				console.error('Failed to refresh token', err);
			} else {
				auth.onSignIn({
					refresh_token: refreshToken,
					access_token: res!.access_token,
				});
				// Return user to their previous page
				// Necessary to call here because we need to use services exposed under App
				signInDirector();
			}
		});
		return () => {
			abortController.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Header />
			<Routes>
				<Route path="/login" element={<LoginScreen />} />
				<Route
					path="/"
					element={
						<RequireAuth>
							<Navigate to={{ pathname: '/studies' }} replace />
						</RequireAuth>
					}
				></Route>
				<Route
					path="*"
					element={
						<RequireAuth>
							<Routes>
								<Route path="/studies" element={<StudyListScreen />} />
								<Route path="/studies/:studyId/*" element={<StudyScreen />} />
							</Routes>
						</RequireAuth>
					}
				/>
				<Route element={<Navigate to="/login" replace />} />
			</Routes>
		</>
	);
}

export default App;

function RequireAuth({ children }: React.ComponentProps<any>) {
	let auth = useAuth();
	const location = useLocation();
	return auth?.access_token ? (
		children
	) : (
		<Navigate to="/login" state={{ from: location }} />
	);
}
