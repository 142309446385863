import { Box, makeStyles, Theme } from '@material-ui/core';
import produce from 'immer';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
	Route,
	RouteProps,
	Routes,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { fetchStudyDetails, fetchStudyParticipants, syncStudyParticipants } from '../../api';
import { useBailout } from '../../hooks/useBailout';
import { FetchError, ParticipantListing, ParticipantsFetchResponse, StudyContext, StudyDetails, StudyParticipants } from '../../types';
import StudySummary from '../StudySummary';
import ParticipantScreen from './ParticipantScreen';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'hidden',
	},
}));

type StudyParticipantState = {
	fetching: boolean,
	participants: StudyParticipants,
	error?: FetchError,
}

const initialParticipantState: StudyParticipantState = { fetching: false, participants: undefined }

type ParticipantDataAction = {
	type: 'fetch',
} | {
	type: 'success', payload: ParticipantListing[] 
} | {
	type: 'fail', payload: FetchError
} | { 
	type: 'remove', payload: string
}

function pdReducer(state: StudyParticipantState, action: ParticipantDataAction): StudyParticipantState {
	switch (action.type) {
		case 'fetch':
			return { ...state, fetching: true }
		case 'success':
			const newParticipants = produce(state.participants ?? {}, (draft) => {
				action.payload.forEach((participant) => draft[participant.id] = participant);
			})
			return {
				fetching: false,
				participants: newParticipants
			}
		case 'fail':
			return {
				fetching: false,
				participants: state.participants,
				error: action.payload,
			}
		case 'remove':
			const newParticipantData = produce(state.participants ?? {}, (draft) => {
				delete draft[action.payload];
			});
			return {
				...state,
				participants: newParticipantData,
			}
		default:
			console.warn('unexpected action for participant data reducer', action);
			return state;
	}
}

export default function StudyScreen(props: RouteProps) {
	const classes = useStyles();
	const studyId = useParams<{ studyId: string }>().studyId!;

	const [studyData, setStudyData] = useState<StudyDetails>();
	const [error, setError] = useState<FetchError | undefined>();

	const [includeFinalized, setIncludeFinalized] = useState(false);

	const [participantData, pdDispatch] = useReducer(pdReducer, initialParticipantState);

	const [syncing, setSyncing] = useState(false);

	const ingestStudyData = useCallback(([payload, error]:
		| [null, FetchError]
		| [StudyDetails, null]) => {
		if (!setStudyData || !setError) return;
		if (!error) {
			setStudyData(payload!);
			setError(undefined);
		} else {
			setError(error);
		}
	}, []
	);

	useEffect(
		function fetchStudyData() {
			const [promise, abortController] = fetchStudyDetails(studyId);
			promise.then(ingestStudyData);

			return () => {
				abortController.abort();
			};
		},
		[studyId, ingestStudyData]
	);

	const ingestParticipantsData = useCallback(() => {
		pdDispatch({ type: 'fetch' });

		const [fetchResults, abortController] = fetchStudyParticipants(studyId, includeFinalized ? 'finalized' : '');

		fetchResults.then(([
			payload,
			error,
		]: [null, FetchError] | [ParticipantsFetchResponse, null]) => {
			if (!error) {
				pdDispatch({ type: 'success', payload: payload!.participants_data });
			} else {
				pdDispatch({ type: 'fail', payload: error });
			}
		});

		return abortController;

	}, [studyId, includeFinalized]);

	useEffect(
		function fetchParticipantData() {
			if (!studyData) {
				return;
			}
			const abort = ingestParticipantsData();
			return () => {
				abort.abort();
			}
		},
		[studyData, includeFinalized, ingestParticipantsData]
	)

	const handleSyncStudy = useCallback(() => {
		setSyncing(true);
		syncStudyParticipants(studyId)[0]
			.then(ingestParticipantsData)
			.finally(() => {
				setSyncing(false);
			});
	}, [studyId, ingestParticipantsData]
	);

	const navigate = useNavigate();

	const handleRemoval = useCallback(
		(participantId: string) => {
			pdDispatch({ type: 'remove', payload: participantId });
			navigate(`/studies/${studyId}`);
		},
		[navigate, studyId]
	);

	const bailout = useBailout();
	if (bailout) return bailout;

	const { participants } = participantData

	return (
		<Box className={classes.container}>
			<StudyContext.Provider value={studyData ? { ...studyData, participants} : undefined}>
				<Routes>
					<Route
						path="/:participantId"
						element={(
							<ParticipantScreen
								syncing={syncing}
								onSyncStudy={handleSyncStudy}
								onRemovedParticipant={handleRemoval}
							/>
						)}
					/>

					<Route
						path="/"
						element={
							<StudySummary
								{...{ studyId, syncing, error, includeFinalized }}
								onChangeFilter={setIncludeFinalized}
								participants={participants ? Object.values(participants) : participants}
								onSyncParticipants={handleSyncStudy}
							/>
						}
					/>
				</Routes>
			</StudyContext.Provider>
		</Box>
	);
}
