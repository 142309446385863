import { Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import MultiItemList from './MultiItemList';
import { isolateValuesFromInstruments, dateTimeFromRedcap } from './utils';

const VAR_NAME_DRAW_DATE = 'var_blood_draw';

export const QuestDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Bloodwork',
	showCoverage: true,
	renderHeader: ({ redcapFields: redcap, deviceConfig }) => {
		let renderedDrawDate: JSX.Element | string;
		const drawDateVarNames = deviceConfig[VAR_NAME_DRAW_DATE] as string[] | undefined;
		if (drawDateVarNames) {
			const drawTimes = drawDateVarNames.flatMap(drawVarName => isolateValuesFromInstruments(redcap, drawVarName));
			renderedDrawDate = drawTimes.length ? (
				<MultiItemList
					items={drawTimes.map((time) =>
						dateTimeFromRedcap(time)?.toLocaleString(
							DateTime.DATETIME_MED
						) ?? ''
					)}
				/>
			) : (
				'Not recorded'
			);
		} else {
			renderedDrawDate = 'Missing blood draw date field name';
		}

		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Drawn" value={renderedDrawDate} />
				</Grid>
			</Grid>
		);
	},
};

export default QuestDeviceRenderStrategy;
