import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Tooltip,
	Typography,
	useTheme
} from '@material-ui/core';
import produce from 'immer';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
	IoSync as ReprocessingIcon,
	IoTrashOutline as TrashIcon,
	IoCheckmark as DeliveredIcon,
} from 'react-icons/io5';
import {
	DeviceRenderStrategy,
	DeviceStatus,
	FileRecord,
	RedcapData,
	StudyContext,
	ValidityIssue
} from '../../types';
import { handleFileSize } from './renderStrategies/utils';

const useStyles = makeStyles((theme: Theme) => ({
	dialogPaper: {
		minWidth: 400,
		minHeight: 300,
		maxWidth: theme.breakpoints.values.md,
	},
	sectionHeader: {
		margin: theme.spacing(2, 0, 1),
	},
	tableContainer: {
		border: `1px solid ${theme.palette.divider}`,
		minWidth: 400,
		marginBottom: theme.spacing(2),
	},
	note: {
		color: theme.palette.text.disabled,
	},
	contentSpacer: {
		marginBottom: theme.spacing(1),
	},
	tooltip: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		fontSize: 11,
	},
	noWrap: {
		whiteSpace: 'nowrap',
	},
	processed: {},
	verifiedPending: {
		fontStyle: 'italic',
		color: theme.palette.text.disabled,
	},
	rejected: {
		fontStyle: 'italic',
		color: theme.palette.text.disabled,
		textDecoration: 'line-through',
	},
	fileInfo: {
		display: 'flex',
		flexDirection: 'row',
	},
	divider: {
		margin: theme.spacing(3, 0),
	},
	dateContent: {
		margin: theme.spacing(2, 2, 0),
	},
	delivered: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '-1em'
	},

	reprocessIcon: {
		marginLeft: theme.spacing(1),
		color: theme.palette.divider,
		'&:hover': {
			color: theme.palette.primary.main,
		},
		'&.spin': {
			animation: '$spin 1s infinite',
		},
	},
	'@keyframes spin': {
		'0%': {
			transform: 'rotate(0deg)',
		},
		'100%': {
			transform: 'rotate(360deg)',
		},
	},
	deleteButton: {
		// marginLeft: theme.spacing(1),
		color: theme.palette.action.disabledBackground,
		'&:hover': {
			backgroundColor: 'inherit',
			color: theme.palette.error.main,
		},
	},
}));

const fileStyle = (theme: Theme, file: FileRecord) => {
	const issues = file.issues ?? [];
	const commonProps = {};
	if (issues.length > 0) {
		if (issues.some((i) => i.severity !== 'warning')) {
			return {
				...commonProps,
				color: theme.palette.text.disabled,
				textDecorationLine: 'line-through',
				fontStyle: 'italic',
			};
		} else {
			return { ...commonProps, color: theme.palette.warning.dark };
		}
	}
	return commonProps;
};

const issueStyle = (theme: Theme, issue: ValidityIssue) => {
	const { severity } = issue;
	const commonProps = {};
	if (severity === 'error') {
		return {
			...commonProps,
			color: theme.palette.error.main,
		};
	}
	return { ...commonProps, color: theme.palette.warning.dark };
};

function isolateFile(filename: string, deviceStrategy: DeviceRenderStrategy) {
	if (deviceStrategy) {
		if (deviceStrategy.title === 'Empatica E4 Wristband') {
			return filename
				.slice(filename.lastIndexOf('/', filename.lastIndexOf('/') - 1) + 1)
				.replace('/', ' / ');
		}
	}
	return filename.slice(filename.lastIndexOf('/') + 1);
}

const useDeleteDialogStyles = makeStyles((theme: Theme) => ({
	row: {
		border: 'none',
	},
}));

interface DeleteDialogProps {
	open: boolean;
	onClose: () => void;
	onDelete: (keyPath: string) => void;
	filesToDelete: { [obsDate: string]: string[] };
	renderStrategy: DeviceRenderStrategy;
}

function DeleteDialog(props: DeleteDialogProps) {
	const { onClose, onDelete, open, filesToDelete } = props;
	const classes = useDeleteDialogStyles();

	const handleClose = () => {
		onClose();
	};
	const numFiles = Object.values(filesToDelete).flatMap((file) => file).length;

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>
				The following {numFiles > 1 ? `${numFiles} files` : 'file'} will be
				deleted
			</DialogTitle>
			<Divider variant="fullWidth" />
			<DialogContent>
				{Object.entries(filesToDelete).map(
					([observationDate, files]) =>
						files.length > 0 && (
							<Table key={observationDate} size="small">
								<TableHead>
									<TableRow>
										<TableCell>
											<Typography variant="h4">{observationDate}</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{files.map((file) => (
										<TableRow key={file}>
											<TableCell className={classes.row}>
												<Typography>
													{isolateFile(file, props.renderStrategy)}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)
				)}
			</DialogContent>
			<Divider variant="fullWidth" />
			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						Object.values(filesToDelete)
							.flatMap((file) => file)
							.forEach(onDelete);
						handleClose();
					}}
				>
					Delete Files
				</Button>
				<Button variant="outlined" onClick={handleClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}

interface DeviceDetailsProps {
	deviceStrategy?: DeviceRenderStrategy;
	status?: DeviceStatus;
	redcapFields: RedcapData | RedcapData[];
	files?: FileRecord[];
	issues?: ValidityIssue[];
	isClassic: boolean;
	timeZone: string;
	studyConfig: { [key: string]: string | string[] };
	deviceConfig: { [key: string]: string | string[] };
	onDismiss: () => void;
	onIngest: (deviceType: string, file?: File) => void;
	onReprocess: (keyPath: string) => Promise<void>;
	onDelete: (keyPath: string, confirmed: boolean) => Promise<void>;
}

const statusTooltip = ({ status, filename, issues }: FileRecord) => {
	switch (status) {
		case 'pending':
			return 'File is pending verification, please check back later.';
		case 'verified':
			return 'Processing file, please check back later.';

		case 'rejected':
			return `File rejected for one or more reasons: ${issues
				.map((i) => i.message)
				.join(';\n')}`;
		case 'deleted':
			return `File permanently removed: ${issues[issues.length - 1].message}`;
		default:
			return `${filename}`;
	}
};
export default function DeviceDetails({
	deviceStrategy,
	status,
	redcapFields,
	files,
	issues,
	isClassic,
	timeZone: time_zone,
	onDismiss,
	studyConfig,
	deviceConfig,
	onIngest,
	onReprocess,
	onDelete,
}: DeviceDetailsProps) {
	const [selectedFiles, setSelectedFiles] = React.useState<{
		[obsDate: string]: string[];
	}>({});
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};
	function handleSelectFile(
		event: React.MouseEvent<unknown>,
		name: string,
		key: string
	) {
		const selectedIndex = selectedFiles[key]?.indexOf(name);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedFiles[key], name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedFiles[key].slice(1));
		} else if (selectedIndex === selectedFiles[key]?.length - 1) {
			newSelected = newSelected.concat(selectedFiles[key].slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedFiles[key]?.slice(0, selectedIndex),
				selectedFiles[key]?.slice(selectedIndex + 1)
			);
		} else {
			newSelected = [name];
		}
		setSelectedFiles({ ...selectedFiles, [key]: newSelected });
	}
	const handleSelectAllFiles = (
		e: React.ChangeEvent<HTMLInputElement>,
		files: FileRecord[],
		key: string
	) => {
		if (e.target.checked) {
			const newSelecteds = files?.map((f, idx) => f.filename);
			setSelectedFiles({ ...selectedFiles, [key]: newSelecteds ?? [] });
			return;
		}

		setSelectedFiles({ ...selectedFiles, [key]: [] });
	};
	const classes = useStyles();
	const sortedFiles = files?.slice().sort((f1, f2) => {
		const primarySort = f2.modified.localeCompare(f1.modified);
		return primarySort !== 0
			? primarySort
			: f1.filename.localeCompare(f2.filename);
	});

	const filesWithDate: [string, FileRecord][] | null =
		sortedFiles?.map((file) => {
			const pathWithDate = file.filename.split('/').reverse().slice(0, -3); // hardcoded to slice off the /studyId/participantId portion of the path and leaving /YYYYmmdd/rest-of-path/.

			return [pathWithDate[pathWithDate.length - 1], file];
		}) ?? null;

	const groupedFiles = React.useMemo(
		() =>
			filesWithDate?.reduce((previous, current) => {
				const date: string = current[0] as string;

				const file: FileRecord = current[1] as FileRecord;
				let prevDate: FileRecord[] = [file];
				if (previous[date]) {
					previous[date].push(file);
					prevDate = previous[date];
				}
				return { [date]: prevDate, ...previous };
			}, {} as { [key: string]: FileRecord[] }),
		[filesWithDate]
	);

	const statusStyle = useCallback(
		function (status: string) {
			switch (status) {
				case 'pending':
					return classes.verifiedPending;
				case 'verified':
					return classes.verifiedPending;
				case 'proccessed':
					return classes.processed;
				case 'rejected':
				case 'deleted':
					return classes.rejected;
			}
		},
		[classes]
	);
	const theme = useTheme();

	const groupedIssues = useMemo(() => {
		return issues?.reduce((agg, curr) => {
			const dateString = curr.location.split('/')[3];
			let dateIssues = agg[dateString];
			if (!dateIssues) {
				dateIssues = [];
				agg[dateString] = dateIssues;
			}

			dateIssues.push(curr);

			return agg;
		}, {} as { [date: string]: ValidityIssue[] });
	}, [issues]);

	const groupedObservations: { [key: string]: boolean } = React.useMemo(() => {
		if (!groupedFiles || !groupedIssues) return {};

		return Object.keys(groupedFiles)
			.concat(Object.keys(groupedIssues))
			.reduce(
				(agg, curr) => ({
					...agg,
					[curr]: true,
				}),
				{}
			);
	}, [groupedFiles, groupedIssues]);

	const [filesReprocessing, setFilesReprocessing] = React.useState<{
		[rawKey: string]: boolean;
	}>({});

	useEffect(
		function resetFilesReprocessing() {
			// Reset files when we change what files we're looking at
			setFilesReprocessing({});
		},
		[deviceConfig]
	);

	const handleReprocess = useCallback(
		(f: FileRecord) => {
			setFilesReprocessing({ ...filesReprocessing, [f.filename]: true });
			onReprocess(f.filename).then(() => {
				setFilesReprocessing(
					produce(filesReprocessing, (draft) => {
						delete draft[f.filename];
					})
				);
			});
		},
		[filesReprocessing, onReprocess]
	);

	const last_sync = sortedFiles?.[0]?.modified;

	const studyCxt = useContext(StudyContext);
	const canDeleteFiles = studyCxt?.userPermissions.can_delete_files;

	const handleDelete = useMemo(
		() =>
			canDeleteFiles
				? (f: FileRecord) => () => {
						onDelete(f.filename, false);
						setSelectedFiles({});
				  }
				: undefined,
		[canDeleteFiles, onDelete]
	);

	return (
		<Dialog
			onClose={onDismiss}
			open={deviceStrategy !== undefined}
			classes={{
				paper: classes.dialogPaper,
			}}
		>
			{deviceStrategy && (
				<>
					<DialogTitle>{deviceStrategy.title}</DialogTitle>
					<DialogContent dividers>
						{deviceStrategy.renderHeader({
							onIngest,
							status: status!,
							last_sync,
							time_zone,
							isClassic,
							redcapFields,
							studyConfig: studyConfig,
							deviceConfig: deviceConfig,
							// because of union type need to generalize this
						})}

						{Object.keys(groupedObservations).length > 0 && (
							<>
								<Divider className={classes.divider} />

								{Object.keys(groupedObservations)
									.sort((obs1, obs2) => parseInt(obs2) - parseInt(obs1))
									.map((observationDate, idx) => {
										let interpretedDate = DateTime.fromFormat(
											observationDate,
											'yyyyMMdd'
										);
										let humanObsDate = interpretedDate.toLocaleString({
											month: 'long',
											day: 'numeric',
											year:
												interpretedDate.year !== DateTime.now().year
													? 'numeric'
													: undefined,
										});

										const issues = groupedIssues?.[observationDate] ?? null;
										const files = groupedFiles?.[observationDate] ?? null;

										return (
											<div key={observationDate}>
												<div className={classes.sectionHeader}>
													<Typography variant="h4">{humanObsDate}</Typography>
												</div>
												<div className={classes.dateContent}>
													{issues && (
														<TableContainer
															classes={{
																root: classes.tableContainer,
															}}
														>
															<Table
																key={observationDate}
																style={{ minWidth: '400px' }}
																size="small"
															>
																<TableHead>
																	<TableRow>
																		<TableCell>
																			<Typography
																				variant="h5"
																				className={classes.noWrap}
																			>
																				Issue
																			</Typography>
																		</TableCell>
																		<TableCell align="right">
																			<Typography variant="h5">
																				Severity
																			</Typography>
																		</TableCell>
																	</TableRow>
																</TableHead>

																<TableBody>
																	{issues.map((iss, idx) => {
																		const issStyle = issueStyle(theme, iss);
																		return (
																			<TableRow key={idx}>
																				<TableCell scope="row" style={issStyle}>
																					{iss.message}
																				</TableCell>
																				<TableCell
																					align="right"
																					style={issStyle}
																					className={classes.noWrap}
																				>
																					{iss.severity === 'critical'
																						? 'Warning'
																						: iss.severity[0].toUpperCase() +
																						  iss.severity.slice(1)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>
													)}
													{files ? (
														<TableContainer
															classes={{
																root: classes.tableContainer,
															}}
														>
															<Table size="small">
																<TableHead>
																	<TableRow>
																		{canDeleteFiles && (
																			<TableCell padding="checkbox">
																				<Checkbox
																					color="primary"
																					indeterminate={
																						selectedFiles[humanObsDate]
																							?.length > 0 &&
																						selectedFiles[humanObsDate]
																							?.length < files.length
																					}
																					checked={
																						files.length > 0 &&
																						selectedFiles[humanObsDate]
																							?.length === files.length
																					}
																					onChange={(e) =>
																						handleSelectAllFiles(
																							e,
																							files,
																							humanObsDate
																						)
																					}
																					inputProps={{
																						'aria-label': 'select all desserts',
																					}}
																				/>
																			</TableCell>
																		)}
																		<TableCell>
																			<Typography variant="h5">File</Typography>
																		</TableCell>
																		<TableCell align="right">
																			<Typography
																				variant="h5"
																				className={classes.noWrap}
																			>
																				Last Modified
																			</Typography>
																		</TableCell>
																		<TableCell align="right">
																			<Typography
																				variant="h5"
																				className={classes.noWrap}
																			>
																				Size
																			</Typography>
																		</TableCell>
																	</TableRow>
																</TableHead>

																<TableBody>
																	{files.map((f, idx) => {
																		let modified = DateTime.fromISO(
																			f.modified
																		).toLocaleString({
																			month: 'numeric',
																			day: 'numeric',
																			hour: 'numeric',
																			minute: 'numeric',
																		});
																		const filenameStyle = fileStyle(theme, f);
																		const canDeleteFile =
																			canDeleteFiles && f.status !== 'deleted';

																		return (
																			<TableRow
																				key={`${f.filename}-row-${idx}`}
																				onClick={(e) =>
																					handleSelectFile(
																						e,
																						f.filename,
																						humanObsDate
																					)
																				}
																			>
																				<TableCell padding="checkbox">
																					{canDeleteFile && (
																						<Checkbox
																							color="primary"
																							checked={
																								selectedFiles[
																									humanObsDate
																								]?.indexOf(f.filename) > -1
																							}
																						/>
																					)}
																				</TableCell>
																				<TableCell
																					className={`${statusStyle(
																						f.status
																					)} ${classes.fileInfo}`}
																					component="th"
																					scope="row"
																				>
																					{f.delivered && (
																						<Tooltip
																							arrow
																							placement="top-start"
																							classes={{
																								tooltip: classes.tooltip,
																							}}
																							title={`File delivered on 
																							${DateTime.fromISO(f.delivered).toLocaleString(DateTime.DATE_SHORT)}`}
																						>
																							<div className={classes.delivered}>
																								<DeliveredIcon color={theme.palette.primary.main}/>
																							</div>
																						</Tooltip>)
																					}
																					<Tooltip
																						arrow
																						classes={{
																							tooltip: classes.tooltip,
																						}}
																						title={statusTooltip({
																							status: f.status,
																							filename: f.filename,
																							issues: f.issues ?? [''],
																						} as FileRecord)}
																						placement="top-start"
																					>
																						<Typography>
																							{isolateFile(
																								f.filename,
																								deviceStrategy
																							)}
																						</Typography>
																					</Tooltip>
																					<Tooltip
																						arrow
																						classes={{
																							tooltip: classes.tooltip,
																						}}
																						title="Re-run file"
																						placement="top-start"
																					>
																						<IconButton
																							size="small"
																							onClick={(e) => {
																								handleReprocess(f);
																								e.stopPropagation();
																							}}
																							color="primary"
																							disabled={
																								filesReprocessing[f.filename]
																							}
																							className={[
																								classes.reprocessIcon,
																								filesReprocessing[f.filename]
																									? 'spin'
																									: '',
																							].join(' ')}
																						>
																							<ReprocessingIcon />
																						</IconButton>
																					</Tooltip>
																					{canDeleteFile && (
																						<Tooltip
																							arrow
																							classes={{
																								tooltip: classes.tooltip,
																							}}
																							title="Delete file"
																							placement="top-start"
																						>
																							<IconButton
																								size="small"
																								className={classes.deleteButton}
																								onClick={(e) => {
																									handleDelete!(f)();
																									e.stopPropagation();
																								}}
																							>
																								<TrashIcon />
																							</IconButton>
																						</Tooltip>
																					)}
																				</TableCell>

																				<TableCell
																					align="right"
																					className={[
																						classes.noWrap,
																						filenameStyle,
																					].join(' ')}
																				>
																					{modified}
																				</TableCell>
																				<TableCell
																					align="right"
																					className={[
																						classes.noWrap,
																						filenameStyle,
																					].join(' ')}
																				>
																					{handleFileSize(f.size)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>
													) : (
														<span className={classes.note}>
															{status === 'fetching'
																? 'Loading...'
																: 'There are no files to display'}
														</span>
													)}
												</div>
												<DeleteDialog
													open={openDeleteDialog}
													onClose={handleClose}
													onDelete={(keyPath) => onDelete(keyPath, true)}
													filesToDelete={selectedFiles}
													renderStrategy={deviceStrategy}
												/>
											</div>
										);
									})}
							</>
						)}
					</DialogContent>
					<DialogActions>
						{canDeleteFiles && (
							<Button
								variant="outlined"
								color="default"
								disabled={
									Object.values(selectedFiles).flatMap((file) => file)
										.length === 0
								}
								onClick={handleClickOpen}
							>
								Delete
							</Button>
						)}
						<Button variant="outlined" color="primary" onClick={onDismiss}>
							Close
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}
