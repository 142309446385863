import {
	Container,
	Grid,
	makeStyles, Theme, Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { RedcapData, RedcapEvent } from '../types';
import DetailsSectionHeader from './DetailsSectionHeader';
import ExternalLink from './ExternalLink';
import MessagePage from './MessagePage';

const REDCAP_BASE_URL =
	'https://redcap.tamhsc.edu/redcap_v11.1.2/DataEntry/index.php';

interface RedcapFormListProps {
	pid?: number;
	participantCode?: string;
	events?: RedcapEvent[];
	redcapFields?: RedcapData | RedcapData[];
	onSync: () => void;
	busySyncing: boolean;
}

const CIRCLE_SIZE = 12;

const useStyles = makeStyles((theme: Theme) => ({
	linkWrapper: {
		padding: theme.spacing(2, 0),
		display: 'flex',
		alignItems: 'center',
	},
	link: {
		fontWeight: 'bold',
	},
	linkText: {},
	circle: {
		borderRadius: CIRCLE_SIZE * 0.5,
		width: CIRCLE_SIZE,
		height: CIRCLE_SIZE,
		marginRight: theme.spacing(1),
	},
	incompleteCircle: {
		border: `1px solid ${theme.palette.divider}`,
	},
	partialCircle: {
		border: `1px solid ${theme.palette.primary.main}`,
	},
	completedCircle: {
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: theme.palette.primary.light,
	},
}));

export default function RedcapFormList(props: RedcapFormListProps) {
	const {
		pid,
		participantCode,
		events,
		redcapFields,
		onSync,
		busySyncing,
	} = props;

	const recordId = participantCode?.slice(participantCode.lastIndexOf('-') + 1);

	const sortedEvents = events?.sort(
		(e1, e2) => e1.event_order - e2.event_order
	);

	const splitIndex = sortedEvents ? Math.ceil(sortedEvents.length * 0.5) : 0;

	const formList = useMemo(() => {
		if (!sortedEvents) {
			return (
				<MessagePage
					title="Loading..."
					message="Please wait while REDCap data loads"
				/>
			);
		}

		if (Array.isArray(redcapFields)) {
			return (
				<Grid item xs={12} md={12}>
					<Typography variant='subtitle1'>
						Support for classic study form display coming soon
					</Typography>
				</Grid>
			);
		} else {
			return (
				<>
					<Grid item xs={12} md={6}>
						{sortedEvents.slice(0, splitIndex).map((e) => (
							<RedcapFormListItem
								event={e}
								key={e.event_id}
								pid={pid!}
								redcapStatus={redcapFields![`${e.page}_complete`]!}
								recordId={recordId!}
							/>
						))}
					</Grid>
					<Grid item xs={12} md={6}>
						{sortedEvents.slice(splitIndex).map((e) => (
							<RedcapFormListItem
								event={e}
								key={e.event_id}
								pid={pid!}
								redcapStatus={redcapFields![`${e.page}_complete`]}
								recordId={recordId!}
							/>
						))}
					</Grid>
				</>
			);
		}
	}, [pid, recordId, redcapFields, sortedEvents, splitIndex]);

	return (
		<>
			<DetailsSectionHeader
				title="REDCap"
				action={{
					title: 'Sync',
					action: onSync,
				}}
				actionDisabled={busySyncing}
			/>

			<Container maxWidth="md">
				<Grid container>{formList}</Grid>
			</Container>
		</>
	);
}

function RedcapFormListItem({
	redcapStatus,
	event,
	pid,
	recordId,
}: {
	redcapStatus: string;
	event: RedcapEvent;
	pid: number;
	recordId: string;
}) {
	// TODO: Pass props into useStyles and simplify classes
	const classes = useStyles();
	const circleClass =
		redcapStatus === '2'
			? classes.completedCircle
			: redcapStatus === '1'
			? classes.partialCircle
			: classes.incompleteCircle;

	return (
		<div key={event.event_id} className={classes.linkWrapper}>
			<div className={classes.circle + ' ' + circleClass} />
			<ExternalLink
				variant="secondary"
				className={classes.link}
				href={`${REDCAP_BASE_URL}?pid=${pid}&page=${event.page}&event_id=${event.event_id}&id=${recordId}`}
			>
				{event.name}
			</ExternalLink>
		</div>
	);
}
