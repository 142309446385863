import {
	makeStyles,
	Theme,
	Tooltip,
	Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { ValidityIssue, FileIssue } from '../../../types';

const useStyles = makeStyles<Theme>((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		border: `1px solid ${theme.palette.divider}`,
		marginTop: theme.spacing(1),
		minWidth: 400,
	},
	alert: {
		padding: theme.spacing(0.5, 1),
		margin: theme.spacing(0.5, 0),
	},
	alertContent: { padding: 0 },
}));

const FileIssueExplanations = (props: {
	filename: string;
	issues: ValidityIssue[] | FileIssue[];
	isDirectory: boolean;
}) => {
	const { filename, issues, isDirectory } = props;
	const classes = useStyles(props);

	return (
		<>
			<Typography variant="h5" color="inherit">
				{filename}
			</Typography>
			{issues &&
				issues.length > 0 &&
				issues?.map(({ message: issue, severity }: ValidityIssue | FileIssue, idx) => {
					return (
						<Alert
							key={idx}
							classes={{
								root: classes.alert,
								icon: classes.alertContent,
								message: classes.alertContent,
							}}
							severity={
								severity === 'critical'
									? isDirectory
										? 'warning'
										: 'error'
									: severity
							}
						>
							{issue}
						</Alert>
					);
				})}
		</>
	);
};

interface FileTooltipProps {
	filename: string;
	issues?: ValidityIssue[] | FileIssue[];
	isDirectory: boolean;
	children: JSX.Element;
}

export default function FileTooltip(props: FileTooltipProps) {
	const classes = useStyles(props);

	const { filename, issues, isDirectory, children } = props;
	return (
		<Tooltip
			classes={{
				tooltip: classes.tooltip,
				tooltipArrow: classes.tooltipArrow,
			}}
			title={FileIssueExplanations({
				filename,
				issues: issues ?? [],
				isDirectory,
			})}
			arrow
		>
			{children}
		</Tooltip>
	);
}
