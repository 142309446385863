import { DateTime } from 'luxon';
import { RedcapData } from '../../../types';
import MultiItemList from './MultiItemList';
export const DATE_FORMAT_REDCAP = 'yyyy-MM-dd';
const DATE_FORMAT_REDCAP_HHMM = 'yyyy-MM-dd HH:mm';
const DATE_FORMAT_REDCAP_HHMMSS = 'yyyy-MM-dd HH:mm:ss';
export const VAR_NAME_DEFAULT_SENSOR_APPLICATION = 'var_sensor_application';
export const VAR_NAME_DEFAULT_SENSOR_REMOVAL = 'var_sensor_removal';
export function redcapSensorAppliedTime(
	redcap: RedcapData | RedcapData[],
	variable: string,
	studyConfig: { [key: string]: string | string[] },
	deviceConfig: { [key: string]: string | string[] }
): string {
	return redcapSensorTime(
		redcap,
		variable,
		studyConfig,
		deviceConfig,
		VAR_NAME_DEFAULT_SENSOR_APPLICATION
	);
}
export function redcapSensorRemovalTime(
	redcap: RedcapData | RedcapData[],
	variable: string,
	studyConfig: { [key: string]: string | string[] },
	deviceConfig: { [key: string]: string | string[] }
) {
	return redcapSensorTime(
		redcap,
		variable,
		studyConfig,
		deviceConfig,
		VAR_NAME_DEFAULT_SENSOR_REMOVAL
	);
}

function redcapSensorTime(
	redcap: RedcapData | RedcapData[],
	variable: string,
	studyConfig: { [key: string]: string | string[] },
	deviceConfig: { [key: string]: string | string[] },
	sensorTimeVariable: string
) {
	let renderedTime: string = 'Not set';
	let timeFieldName = getPreferredRedcapField(
		variable,
		deviceConfig,
		sensorTimeVariable,
		studyConfig
	);

	if (Array.isArray(redcap)) {
		// Redcap is classic
		const times = isolateValuesFromInstruments(redcap, timeFieldName);
		if (times.length) {
			renderedTime = times
				.map((time) =>
				dateTimeFromRedcap(time)?.toLocaleString(
						DateTime.DATETIME_MED
					) ?? null
				)
				.filter(t => t !== null)
				.join(', ');
		}
	} else if (redcap[timeFieldName]) {
		renderedTime = dateTimeFromRedcap(
			redcap[timeFieldName]
		)!.toLocaleString(DateTime.DATETIME_MED);
	}

	return renderedTime;
}
const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'];

export function getPreferredRedcapField(
	deviceVariable: string,
	deviceConfig: { [key: string]: string | string[] },
	studyVariable: string,
	studyConfig: { [key: string]: string | string[] }
) {
	let timeFieldName = deviceConfig[deviceVariable] as string;
	if (!timeFieldName) {
		timeFieldName = studyConfig[studyVariable] as string;
	}
	return timeFieldName;
}

export function handleFileSize(size: number) {
	let filesize = size;
	let suffix_idx = 0;
	while (filesize > 999) {
		filesize = filesize * 0.001;
		suffix_idx++;
	}
	return `${filesize.toFixed()}${units[suffix_idx]}`;
}

const isObject = (item: any) => {
	return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep: any = (target: any, ...sources: any) => {
	if (!sources.length) return target;
	var source = sources.shift();
	if (Array.isArray(source)) {
		source = source[0];
	}

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key])
					Object.assign(target, {
						[key]: {},
					});
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, {
					[key]: source[key],
				});
			}
		}
	}
	return mergeDeep(target, ...sources);
};

export function getFirstValue(
	redcapFields: RedcapData | RedcapData[],
	value: string
) {
	return Array.isArray(redcapFields)
		? redcapFields.find(
				(instrument) =>
					instrument[value] !== undefined && instrument[value] !== ''
		  )?.[value]
		: redcapFields[value];
}

export function getLatestValue(
	redcapFields: RedcapData | RedcapData[],
	value: string
) {
	return Array.isArray(redcapFields)
		? redcapFields
				.reverse()
				.find((instrument) => instrument[value] !== undefined)?.[value]
		: redcapFields[value];
}

export function isVarSet(value: string | undefined) {
	return value !== undefined && value !== '';
}

export function isolateValuesFromInstruments(
	instruments: RedcapData | RedcapData[],
	variable: string
): string[] {
	if (Array.isArray(instruments)) {
		return instruments
			.map((instrument) => instrument[variable])
			.filter(isVarSet);
	}
	return [instruments[variable]];
}

export function renderFields(
	lotFieldNames: string[] | undefined,
	redcap: RedcapData | RedcapData[],
	fieldTarget: string
): string | JSX.Element {
	const fieldsRaw = lotFieldNames
		?.flatMap((fieldName) => isolateValuesFromInstruments(redcap, fieldName))
		.filter((lot) => lot && lot !== '') ?? [
		`Missing ${fieldTarget} field name configuration`,
	];
	return fieldsRaw.length ? <MultiItemList items={fieldsRaw} /> : 'Not set';
}

export function dateTimeFromRedcap(dateTime: string): DateTime | null {
	if (!dateTime.length) {
		return null;
	}

	let rv = DateTime.fromFormat(dateTime, DATE_FORMAT_REDCAP_HHMM);
	if (rv.isValid) {
		return rv;
	}
	rv = DateTime.fromFormat(dateTime, DATE_FORMAT_REDCAP_HHMMSS);
	return rv.isValid ? rv : null;
}