import { Button, Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import {
	redcapSensorAppliedTime,
	redcapSensorRemovalTime,
	renderFields,
} from './utils';

const DEVICE_TYPE = 'Empatica_E4';
const VAR_NAME_SENSOR_APPLIED = 'var_e4_applied';
const VAR_NAME_SERIAL_NUMBERS = 'vars_e4_sn';
const VAR_NAME_SENSOR_REMOVAL = 'var_e4_removal';

export const EmpaticaDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Empatica E4 Wristband',
	sensorApplicationVar: VAR_NAME_SENSOR_APPLIED,
	sensorRemovalVar: VAR_NAME_SENSOR_REMOVAL,
	showCoverage: true,
	renderHeader: ({
		onIngest,
		status,
		redcapFields,
		last_sync,
		deviceConfig,
		studyConfig,
	}) => {
		const actionMessage =
			status === 'idle'
				? 'Sync'
				: status === 'fetching'
				? 'Downloading...'
				: 'Syncing...';

		const snFieldNames = deviceConfig[VAR_NAME_SERIAL_NUMBERS] as
			| string[]
			| undefined;
		const snRendered = renderFields(
			snFieldNames,
			redcapFields,
			'serial number'
		);
		const appliedRendered = redcapSensorAppliedTime(
			redcapFields,
			VAR_NAME_SENSOR_APPLIED,
			studyConfig,
			deviceConfig
		);
		const removalRendered = redcapSensorRemovalTime(
			redcapFields,
			VAR_NAME_SENSOR_REMOVAL,
			studyConfig,
			deviceConfig
		);
		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Serial Number" value={snRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Removed" value={removalRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Assigned" value={appliedRendered} />
				</Grid>

				<Grid item xs={12} sm={6}>
					<DeviceDetailItem
						attribute="Last sync"
						value={
							last_sync
								? DateTime.fromISO(last_sync).toLocaleString(
										DateTime.DATETIME_MED
								  )
								: "Not sync'd"
						}
						action={
							<Button
								variant="outlined"
								color="primary"
								disabled={status !== 'idle'}
								onClick={() => onIngest(DEVICE_TYPE)}
							>
								{actionMessage}
							</Button>
						}
					/>
				</Grid>
			</Grid>
		);
	},
};

export default EmpaticaDeviceRenderStrategy;
