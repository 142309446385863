import { Button, Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import { getFirstValue, redcapSensorAppliedTime, redcapSensorRemovalTime } from './utils';

const DEVICE_TYPE = 'Oura_Ring';
const VAR_NAME_SENSOR_APPLIED = 'var_oura_applied';
const VAR_NAME_SERIAL_NUMBER = 'var_oura_sn';
const VAR_NAME_EMAIL_ADDRESS = 'var_oura_email';
const VAR_NAME_SENSOR_REMOVAL = 'var_oura_removal';

export const OuraDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Oura Ring',
	sensorApplicationVar: VAR_NAME_SENSOR_APPLIED,
	sensorRemovalVar: VAR_NAME_SENSOR_REMOVAL,
	showCoverage: true,
	renderHeader: ({
		onIngest,
		status,
		redcapFields,
		last_sync,
		studyConfig,
		deviceConfig,
	}) => {
		const varSn = deviceConfig[VAR_NAME_SERIAL_NUMBER] as string | undefined;
		const snRendered = varSn
			? getFirstValue(redcapFields, varSn) ?? 'Not set'
			: 'Missing serial number field name configuration';

		const varEmail = deviceConfig[VAR_NAME_EMAIL_ADDRESS] as string | undefined;
		const emailRendered = varEmail
			? getFirstValue(redcapFields, varEmail) ?? 'Not set'
			: 'Missing email field name configuration';

		const appliedRendered = redcapSensorAppliedTime(
			redcapFields,
			VAR_NAME_SENSOR_APPLIED,
			studyConfig,
			deviceConfig
		);
		const removalRendered = redcapSensorRemovalTime(
			redcapFields,
			VAR_NAME_SENSOR_REMOVAL,
			studyConfig,
			deviceConfig
		);
		const actionMessage =
			status === 'idle'
				? 'Sync'
				: status === 'fetching'
				? 'Downloading...'
				: 'Syncing...';

		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Serial Number" value={snRendered} />
				</Grid>
				
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Assigned" value={appliedRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Oura Email" value={emailRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Removed" value={removalRendered} />
				</Grid>
				<Grid item xs={12} sm={6} />
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem
						attribute="Last sync"
						value={
							last_sync
								? DateTime.fromISO(last_sync).toLocaleString(
										DateTime.DATETIME_MED
								  )
								: "Not sync'd"
						}
						action={
							<Button
								variant="outlined"
								color="primary"
								disabled={status !== 'idle'}
								onClick={() => onIngest(DEVICE_TYPE)}
							>
								{actionMessage}
							</Button>
						}
					/>
				</Grid>
			</Grid>
		);
	},
};

export default OuraDeviceRenderStrategy;
