export function decode(b64: string): string {
    return atob(b64);
}

export function urlDecode(b64: string): string {
    return decode(b64.replace(/-/g, '+').replace(/_/g, '/'));
}

export function encode(data: string, pad: boolean = true): string {
    const encoded = btoa(data);
    return pad ? encoded : encoded.replace(/=*$/, '');
}

export function urlEncode(data: string, pad: boolean = true): string {
    return encode(data, pad).replace(/\+/g, '-').replace(/\//g, '_');
}
