import { Typography } from '@material-ui/core';
import React from 'react';

export default function MultiItemList(props: { items: string[] }) {
	return (
		<div>
			{props.items.map((item, idx) => (
				<Typography key={idx} variant="body2" style={{ display: 'block' }}>{item}</Typography>
			))}
		</div>
	);
}
