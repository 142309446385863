import { Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	toolbar: {
		height: 44,
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2),
		padding: theme.spacing(0, 5, 0, 7),
		borderTop: `1px solid ${theme.palette.divider}`,
		borderBottom: `1px solid ${theme.palette.divider}`,
		alignItems: 'center',
	},

	title: {},
	button: {
		fontSize: '1.1rem',
		marginRight: '15%',
	},
}));

interface DetailsSectionHeaderProps {
	title: string;
	action?: {
		title: string;
		action: () => void;
	};
	actionDisabled?: boolean;
}

export default function DetailsSectionHeader({
	title,
	action,
	actionDisabled,
}: DetailsSectionHeaderProps) {
	const classes = useStyles();

	return (
		<div className={classes.toolbar}>
			<Typography variant="h3">{title}</Typography>
			{action && (
				<Button
					variant="text"
					color="primary"
					onClick={action.action}
					className={classes.button}
					size="large"
					disabled={actionDisabled}
				>
					{action.title}
				</Button>
			)}
		</div>
	);
}
