import { Typography } from '@material-ui/core';
import { DeviceRenderStrategy } from '../../../types';

export const UnsupportedDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Unknown Device',
	showCoverage: false,
	renderHeader: () => (
		<div>
			<Typography>This device is not yet supported</Typography>
		</div>
	),
};

export default UnsupportedDeviceRenderStrategy;
