import React, { useEffect, useMemo } from 'react';
import {
	ConfigValue,
	DeviceRenderStrategy,
	DeviceStatus,
	FetchError,
	NetworkDeviceDetails
} from '../../types';
import DeviceDetails from './DeviceDetails';
import { getRenderStrategy, LoadingRenderStrategy } from './renderStrategies';

type RedcapData = { [field: string]: string };

interface DeviceDetailsWrapperProps {
	device_type?: string;
	deviceDetails?: NetworkDeviceDetails;
	redcapFields?: RedcapData | RedcapData[];
	isClassic: boolean;
	studyConfig: ConfigValue;
	deviceConfig: ConfigValue;
	status?: DeviceStatus;
	error?: FetchError;
	timeZone: string;
	onDismiss: () => void;
	onFetch: (deviceType: string) => void;
	onIngest: (deviceType: string) => void;
	onReprocess: (filePath: string) => Promise<void>;
	onDelete: (filePath: string, confirmed: boolean) => Promise<void>;
}

export default function DeviceDetailsWrapper(props: DeviceDetailsWrapperProps) {
	const {
		device_type,
		status,
		error,
		deviceDetails,
		redcapFields,
		isClassic,
		studyConfig,
		deviceConfig,
		timeZone,
		onDismiss,
		onIngest,
		onFetch,
		onReprocess,
		onDelete,
	} = props;

	useEffect(() => {
		if (device_type) {
			onFetch(device_type);
		}
	}, [device_type, onFetch]);

	const deviceStrategy: DeviceRenderStrategy | undefined = useMemo(() => {
		if (!device_type) return undefined;

		if (!error && !deviceDetails) return LoadingRenderStrategy;

		return getRenderStrategy(device_type, error);
	}, [device_type, error, deviceDetails]);

	return (
		<DeviceDetails
			{...{
				onDismiss,
				deviceStrategy,
				isClassic,
				studyConfig,
				deviceConfig,
				status,
				timeZone,
				onIngest,
				onReprocess: (keyPath) => onReprocess(keyPath).then(() => onFetch(device_type!)),
				onDelete: (keyPath, confirmed) => onDelete(keyPath, confirmed).then(() => onFetch(device_type!))
			}}
			redcapFields={redcapFields ?? {}}
			files={deviceDetails?.files}
			issues={deviceDetails?.issues}
		/>
	);
}
