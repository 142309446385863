import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { IoAlert } from 'react-icons/io5';
import {
	ConfigValue,
	DeviceServerConfig,
	DeviceMeta,
	DeviceStatus,
} from '../types';
import ClickableText from './ClickableText';
import theme from './theme';
import CoverageGraph from './deviceDetails/CoverageGraph';

const useStyles = makeStyles((theme: Theme) => ({
	row: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	meta: {
		flex: 1,
	},
	deviceNameRow: {
		marginTop: 7,
	},
	issueIcon: {
		backgroundColor: 'red',
		width: 24,
		height: 24,
	},
	link: {
		textAlign: 'left',
	},
	actions: {
		width: '200px',
		alignItems: 'flex-start',
	},
	button: {},
	uploadTime: {
		marginTop: -theme.spacing(1),
		textAlign: 'left',
	},
	instructions: {
		margin: theme.spacing(0, 1),
		fontSize: '.8rem',
		color: theme.palette.text.secondary,
		textDecoration: 'underline',
	},
	issueAlert: {
		marginTop: theme.spacing(1),
		marginLeft: '-16px',
	},
	boxWidth: {
		width: '200px',
	},
}));

interface DeviceListingProps extends DeviceMeta {
	disabled?: boolean;
	onShowDetails?: (deviceType: string) => void;
	onShowInstructions?: (deviceType: string) => void;
	onIngest: (deviceType: string, upload: boolean) => void;
	studyConfig: ConfigValue;
	deviceConfig: DeviceServerConfig;
	status: DeviceStatus;
	appliedTime: DateTime | null;
	removalTime: DateTime | null;
}

export default function DeviceListing(props: DeviceListingProps) {
	const classes = useStyles();
	const {
		device_type,
		disabled,
		last_submit,
		status,
		onIngest,
		onShowDetails,
		onShowInstructions,
		issue_severity,
		deviceConfig,
		appliedTime,
		removalTime,
		logged_days,
	} = props;

	const { cloud_sync, name } = deviceConfig;

	const actionMessage = useMemo(() => {
		const root = cloud_sync ? 'Sync' : 'Upload';
		switch (status) {
			case undefined:
				return 'Loading...';
			case 'idle':
				return root;
			case 'fetching':
				return 'Updating...';
			case 'ingesting':
				return `${root}ing...`;
			default:
				console.warn('Unexpected status', status);
				return '<Misconfigured>';
		}
	}, [status, cloud_sync]);

	return (
		<Box marginTop={4} display="flex" flexDirection="row">
			<Box marginLeft={4} className={classes.boxWidth}>
				<Box display="flex" alignItems="center">
					{issue_severity > 0 && (
						<div>
							<IoAlert
								color={
									issue_severity === 1
										? theme.palette.warning.dark
										: theme.palette.error.main
								}
								className={classes.issueAlert}
							/>
						</div>
					)}
					<div className={classes.deviceNameRow}>
						<ClickableText
							className={classes.link}
							disabled={disabled}
							onClick={() => onShowDetails?.(device_type)}
						>
							{name}
						</ClickableText>
					</div>
				</Box>
				<Typography
					className={classes.uploadTime}
					variant="subtitle2"
					color="textSecondary"
				>
					{last_submit ? DateTime.fromISO(last_submit).toRelative() : ''}
				</Typography>
			</Box>
			<Box marginLeft={4} marginBottom={1} flex={2}>
				<CoverageGraph
					appliedTime={appliedTime}
					removalTime={removalTime}
					loggedDays={logged_days}
				/>
			</Box>
			<Box marginLeft={4} whiteSpace="nowrap" className={classes.actions}>
				<Button
					disabled={disabled || status !== 'idle'}
					variant="outlined"
					color="primary"
					onClick={() => onIngest(device_type, !cloud_sync)}
				>
					{actionMessage}
				</Button>
				<ClickableText
					className={classes.instructions}
					secondary
					disabled={disabled}
					onClick={() => onShowInstructions?.(device_type)}
				>
					instructions
				</ClickableText>
			</Box>
		</Box>
	);
}
