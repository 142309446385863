import { Grid } from '@material-ui/core';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import {
	redcapSensorAppliedTime,
	redcapSensorRemovalTime,
	renderFields,
} from './utils';

const VAR_NAME_SENSOR_APPLIED = 'var_gcm_applied';
const VAR_NAME_LOT_NUMBER = 'vars_cgm_lot';
const VAR_NAME_CODE = 'vars_cgm_code';
const VAR_NAME_SENSOR_REMOVAL = 'var_cgm_removal';

export const DexcomG6DeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Dexcom CGM',
	sensorApplicationVar: VAR_NAME_SENSOR_APPLIED,
	sensorRemovalVar: VAR_NAME_SENSOR_REMOVAL,
	showCoverage: true,
	renderHeader: ({ redcapFields, studyConfig, deviceConfig }) => {
		const lotFieldNames = deviceConfig[VAR_NAME_LOT_NUMBER] as
			| string[]
			| undefined;
		const lotRendered = renderFields(lotFieldNames, redcapFields, 'lot');

		const codeFieldNames = deviceConfig[VAR_NAME_CODE] as string[] | undefined;
		const transCodeRendered = renderFields(
			codeFieldNames,
			redcapFields,
			'code'
		);

		const appliedRendered = redcapSensorAppliedTime(
			redcapFields,
			VAR_NAME_SENSOR_APPLIED,
			studyConfig,
			deviceConfig
		);
		const removalRendered = redcapSensorRemovalTime(
			redcapFields,
			VAR_NAME_SENSOR_REMOVAL,
			studyConfig,
			deviceConfig
		);
		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Sensor Lot #" value={lotRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Assigned" value={appliedRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem
						attribute="Transmitter Code"
						value={transCodeRendered}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Removed" value={removalRendered} />
				</Grid>
			</Grid>
		);
	},
};

export default DexcomG6DeviceRenderStrategy;
