import { DeviceRenderStrategy } from '../../../types';
import MessagePage from '../../MessagePage';

export const PhilipsDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Philips Bedside Monitor',
	showCoverage: true,
	renderHeader: () => (
		<MessagePage
			title="No REDCap Data"
			message="This study does not capture Philips data in REDCap"
			abbreviated
		/>
	),
};

export default PhilipsDeviceRenderStrategy;
