import {
	Box,
	Container,
	makeStyles,
	Theme,
	Typography,
	useTheme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React, { useContext, useMemo } from 'react';
import {
	DeviceInfo,
	DeviceMeta,
	DeviceServerConfig,
	RedcapData,
	StudyContext
} from '../types';
import DetailsSectionHeader from './DetailsSectionHeader';
import { getRenderStrategy } from './deviceDetails/renderStrategies';
import {
	dateTimeFromRedcap,
	getFirstValue,
	getLatestValue,
	getPreferredRedcapField,
	VAR_NAME_DEFAULT_SENSOR_APPLICATION,
	VAR_NAME_DEFAULT_SENSOR_REMOVAL
} from './deviceDetails/renderStrategies/utils';
import DeviceListing from './DeviceListing';

const useStyles = makeStyles((theme: Theme) => ({
	obsInstructions: {
		// textAlign: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
}));
interface ParticipantDeviceListProps {
	deviceRegistry: { [deviceType: string]: DeviceInfo };
	participantDevices: DeviceMeta[];
	deviceProfiles: DeviceServerConfig[];
	redcapFields: RedcapData | RedcapData[];
	canIngest: boolean;
	onIngest: (deviceType: string) => void;
	onSelectDevice: (deviceType: string) => void;
	onShowInstructions: (deviceType: string) => void;
}
export default function ParticipantDeviceList(
	props: ParticipantDeviceListProps
) {
	const theme = useTheme();
	const {
		canIngest,
		deviceRegistry,
		participantDevices,
		deviceProfiles,
		redcapFields,
		onSelectDevice,
		onIngest,
		onShowInstructions,
	} = props;
	const { devices, config, prefix } = useContext(StudyContext)!;
	const { obsInstructions: clxInstructions } = useStyles();
	const configMessage = useMemo(() => {
		if (canIngest) return null;

		return config?.observation_fields ? (
			<Alert severity="info" className={clxInstructions}>
				Record the participant's observation date in REDCap to enable data
				upload/sync
			</Alert>
		) : (
			<Alert severity="error" className={clxInstructions}>
				Study not configured for observation dates. Please contact{' '}
				<a
					href={`mailto:sdm-support@tamu.edu?subject=Study%20'${prefix.replaceAll(
						' ',
						'%20'
					)}'%20missing%20observation%20config&body=Add%20missing%20observation%20date%20config%20to%20study%20to%20enable%20data%20ingest.`}
				>
					support
				</a>
			</Alert>
		);
	}, [canIngest, config, prefix, clxInstructions]);

	return (
		<>
			<DetailsSectionHeader
				title="Devices"
				// action={{ title: 'Sync', action: () => {} }}
			/>

			<Container maxWidth="md">
				{configMessage}

				{devices
					.filter((studyDevice) => {
						// const redcapRevealVar = 'patient_mon_uploaded';
						const redcapRevealVar = studyDevice.config?.redcapDeviceUsedField;
						if (redcapRevealVar) {
							// Only show the device if the REDCap variable's value says to
							return getLatestValue(redcapFields, redcapRevealVar) === '1';
						}
						return true;
					})
					.sort((a, b) => {
						return a.name.localeCompare(b.name);
					})
					.map((deviceConfig) => {
						const deviceType = deviceConfig.device_type;
						const device = participantDevices.find(
							(d) => d.device_type === deviceType
						)!;
						const regDevice = deviceRegistry[deviceType];

						const participantDevice = deviceRegistry[deviceType];

						const deviceProfile = deviceProfiles.find(
							(d) => d.device_type === deviceType
						);

						const strategy = getRenderStrategy(deviceType);
						let appliedTime: DateTime | null;
						let removedTime: DateTime | null;

						if (strategy.showCoverage) {
							const redcapFieldAppliedTime = getPreferredRedcapField(
								strategy.sensorApplicationVar ?? '-1',
								deviceConfig.config ?? {},
								VAR_NAME_DEFAULT_SENSOR_APPLICATION,
								config
							);
							const redcapFieldRemovalTime = getPreferredRedcapField(
								strategy.sensorRemovalVar ?? '-1',
								deviceConfig.config ?? {},
								VAR_NAME_DEFAULT_SENSOR_REMOVAL,
								config
							);
							const rawAppliedTime = getFirstValue(
								redcapFields,
								redcapFieldAppliedTime
							);
							const rawRemovalTime = getFirstValue(
								redcapFields,
								redcapFieldRemovalTime
							);
							appliedTime = dateTimeFromRedcap(rawAppliedTime ?? '');
							removedTime = dateTimeFromRedcap(rawRemovalTime ?? '');
						} else {
							appliedTime = removedTime = null;
						}

						return (
							<Box key={deviceType} margin={theme.spacing(4, 0)}>
								{!regDevice ||
								!participantDevice ||
								!config ||
								!deviceProfile ? (
									<Typography>Loading...</Typography>
								) : (
									<DeviceListing
										key={deviceType}
										{...device}
										last_submit={regDevice.last_submit}
										issue_severity={regDevice.issue_severity ?? 0}
										disabled={!canIngest}
										onIngest={onIngest}
										onShowDetails={onSelectDevice}
										onShowInstructions={onShowInstructions}
										status={participantDevice.status}
										studyConfig={config}
										deviceConfig={deviceConfig}
										appliedTime={appliedTime}
										removalTime={removedTime}
									/>
								)}
							</Box>
						);
					})}
			</Container>
		</>
	);
}
