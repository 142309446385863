import grey from '@material-ui/core/colors/grey';
import teal from '@material-ui/core/colors/teal';
// HACK: Necessary in material-ui v4 for dialog to render without errors. Version 5 has a fix and is in beta.
import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';

const theme = createTheme({
	palette: {
		primary: {
			main: teal[500],
		},
		secondary: {
			main: grey[50],
		},
		action: {
			active: grey[900],
		},
	},
	shape: {
		borderRadius: 10,
	},
	typography: {
		button: {
			textTransform: 'none',
		},
		h1: {
			fontSize: '2.5em',
		},
		h2: {
			fontSize: '2em',
			textAlign: 'left',
		},
		h3: {
			fontSize: '1.5em',
			fontWeight: 300,
			color: grey[700],
		},
		h4: {
			fontSize: '1.25em',
			fontWeight: 'bold',
			color: grey[500],
		},
		h5: {
			fontSize: '1.05em',
			fontWeight: 'bold',
			color: grey[700],
		},
	},
	props: {
		MuiAppBar: {
			elevation: 0,
		},
		MuiButtonBase: {
			disableRipple: true,
		},
		MuiButton: {
			disableElevation: true,
		},
		MuiCard: {
			variant: 'outlined',
		},
		MuiListItemIcon: {
			style: {
				minWidth: '32px',
			}
		},
	},
});
export type AppTheme = typeof theme;

export default theme;
