import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	useTheme
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
	IoAlert,
	IoCheckmark as CompletedIcon,
	IoCheckmarkDone as FinalizedIcon
} from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { ParticipantListing } from '../types';

export default function ParticipantListItem({
	id,
	finalized,
	completed,
	issue_severity,
	last_upload,
	selected,
}: ParticipantListing & { selected: boolean }) {
	const [statusMessage, statusDateTime]: [string | null, DateTime | null] =
		useMemo(() => {
			let significantDate: DateTime;
			if (finalized) {
				significantDate = DateTime.fromISO(finalized);
				return [
					`Finalized ${significantDate.toLocaleString(
						DateTime.DATETIME_SHORT
					)}`,
					significantDate,
				];
			} else if (completed) {
				significantDate = DateTime.fromISO(completed);
				return [
					`Sent ${significantDate.toLocaleString(DateTime.DATETIME_SHORT)}`,
					significantDate,
				];
			} else if (last_upload) {
				significantDate = DateTime.fromISO(last_upload);
				return [null, significantDate];
			} else {
				return [null, null];
			}
		}, [completed, finalized, last_upload]);

	const theme = useTheme();

	const { studyId } = useParams<{ studyId: string }>();

	const statusIcon =
		finalized || completed ? (
			finalized ? (
				<FinalizedIcon color={theme.palette.primary.main} />
			) : (
				<CompletedIcon color={theme.palette.primary.main} />
			)
		) : null;

	const severityIcon = issue_severity > 0 && (
		<IoAlert
			color={
				issue_severity === 1
					? theme.palette.warning.dark
					: theme.palette.error.main
			}
		/>
	);

	return (
		<ListItem
			key={id}
			button
			component={Link}
			to={`/studies/${studyId}/${id}`}
			divider
			selected={selected}
		>
			{statusMessage ? (
				<Tooltip title={statusMessage}>
					<ListItemIcon>
						{statusIcon}
						{severityIcon}
					</ListItemIcon>
				</Tooltip>
			) : (
				<ListItemIcon>{severityIcon}</ListItemIcon>
			)}
			<ListItemText
				primary={id}
				secondary={
					statusDateTime ? statusDateTime.toRelative() : 'No uploaded data'
				}
				style={{
					paddingLeft: theme.props!.MuiListItemIcon!.style!.minWidth,
				}}
			/>
		</ListItem>
	);
}
