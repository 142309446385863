import { urlDecode as decode } from './base64';

export interface JWSHeader {
    /**
     * Algorithm: the cryptographic algorithm used to secure the JWS
     */
    alg:
        | 'HS256'
        | 'HS384'
        | 'HS512'
        | 'RS256'
        | 'RS384'
        | 'RS512'
        | 'ES256'
        | 'ES384'
        | 'ES512'
        | 'PS256'
        | 'PS384'
        | 'PS512'
        | 'Ed25519'
        | 'none';

    /**
     * JWK Set URL: a URI that refers to a resource for a set of JSON-encoded public keys, one of which corresponds to the key used to digitally sign the JWS
     */
    jku?: string;

    /**
     * JSON Web Key: the public key that corresponds to the key used to digitally sign the JWS.
     */
    jwk?: string;

    /**
     * Key ID: a hint indicating which key was used to secure the JWS
     */
    kid?: string;

    /**
     * X.509 URL: a URI that refers to a resource for the X.509 public key certificate or chain corresponding to the key used to digitally sign the JWS.
     */
    x5u?: string;

    /**
     * X.509 Certificate Chain: the X.509 public key certificate or certificate chain corresponding to the key used to digitally sign the JWS.
     * Each element in the array is a base64-encoded DER representation of the certificate.
     */
    x5c?: string[];

    /**
     * X.509 Certificate SHA-1 Thumbprint/Fingerprint: a base64url-encoded SHA-1 thumbprint/fingerprint/digest of the DER encoding of the X.509 certificate corresponding to the key used to digitally sign the JWS.
     */
    x5t?: string;

    /**
     * X.509 Certificate SHA-256 Thumbprint/Fingerprint: a base64url-encoded SHA-256 thumbprint/fingerprint/digest of the DER encoding of the X.509 certificate corresponding to the key used to digitally sign the JWS.
     */
    'x5t#S256'?: string;

    /**
     * Type: used to declare the MIME media type of this complete JWS without the "application/" prefix.
     */
    typ?: string;

    /**
     * Content Type: used to declare the MIME media type of the secured content (payload) of this cmplete JWS without the "application/" prefix.
     */
    cty?: string;

    /**
     * Critical: extra headers used in this header that are extensions to the standard.
     */
    crit?: string[];
}

export interface JWTRegisteredClaims {
    /**
     * Issuer: the principal that issued the JWT.
     */
    iss?: string;

    /**
     * Subject: the principal that is the subject of the JWT.
     */
    sub?: string;

    /**
     * Audience: the recipient(s) that the JWT is intended for.
     */
    aud?: string[];

    /**
     * Expiration Time: the time on or after which the JWT will not be accepted.
     */
    exp?: number;

    /**
     * Not Before: the time before which the JWT will not be accepted.
     */
    nbf?: number;

    /**
     * Issued At: the time at which the JWT was issued.
     */
    iat?: number;

    /**
     * JWT ID: a unique identifier for the JWT
     */
    jti?: string;
}

export interface JWS<T extends object> {
    header: JWSHeader;
    payload: JWTRegisteredClaims & T;
    signature: string;
}

class JWTError extends Error {}

export function decodeJws<T extends object>(jws: string): JWS<T> {
    const parts = jws.split('.');

    if (parts.length !== 3) {
        throw new JWTError('malformed token');
    }

    const [header64, payload64, signature64] = parts;

    const header = JSON.parse(decode(header64));
    const payload = JSON.parse(decode(payload64));
    const signature = decode(signature64);

    if (!header || !payload) {
        throw new JWTError('malformed token');
    }

    return { header, payload, signature };
}
