import { Grid } from '@material-ui/core';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import {
	redcapSensorAppliedTime,
	redcapSensorRemovalTime,
	renderFields,
} from './utils';

const VAR_NAME_SENSOR_APPLIED = 'var_emg_applied';
const VAR_NAME_BTIDS = 'vars_emg_btid';
const VAR_NAME_SENSOR_REMOVAL = 'var_emg_removal';

export const ShimmerDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Shimmer3',
	sensorApplicationVar: VAR_NAME_SENSOR_APPLIED,
	sensorRemovalVar: VAR_NAME_SENSOR_REMOVAL,
	showCoverage: false,
	renderHeader: ({ redcapFields, deviceConfig, studyConfig }) => {
		let appliedRendered: string;
		const btidFieldNames = deviceConfig[VAR_NAME_BTIDS] as string[] | undefined;
		const btidRendered = renderFields(
			btidFieldNames,
			redcapFields,
			'bluetooth ID'
		);

		appliedRendered = redcapSensorAppliedTime(
			redcapFields,
			VAR_NAME_SENSOR_APPLIED,
			studyConfig,
			deviceConfig
		);
		const removalRendered = redcapSensorRemovalTime(
			redcapFields,
			VAR_NAME_SENSOR_REMOVAL,
			studyConfig,
			deviceConfig
		);
		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Serial Number" value={btidRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Assigned" value={appliedRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Removed" value={removalRendered} />
				</Grid>
			</Grid>
		);
	},
};

export default ShimmerDeviceRenderStrategy;
