import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { useLayoutEffect, useMemo } from 'react';
import { FileRecord, ValidityIssue } from '../../../types';
import { handleFileSize, mergeDeep } from '../renderStrategies/utils';
import FileTree from './FileTree';

const useStyles = makeStyles<Theme, ReviewProfileDialogProps>((theme) => ({
	action: { alignSelf: 'center', flex: 1 },
	alert: {
		width: '80%',
		paddingTop: 0,
		paddingBottom: 0,
		margin: 2,
		alignSelf: 'center',
	},
	dialogPaper: {
		width: theme.breakpoints.values.sm,
		maxWidth: theme.breakpoints.values.md,
	},
	container: {
		overflow: 'auto',
		padding: 20,
		paddingTop: 0,
	},
	folderTitle: ({ issues }) => ({
		marginTop: (issues ?? []).length > 0 ? theme.spacing(2) : 0,
	}),
	studyFolder: {
		minHeight: 300,
		maxHeight: 400,
		border: `1px solid ${theme.palette.divider}`,
		overflow: 'auto',
	},
	text: { fontSize: 12, paddingLeft: 0, whiteSpace: 'nowrap' },

	message: {
		paddingRight: 20,
		textAlign: 'right',
	},
	providedError: {
		color: theme.palette.error.main,
	},
}));

interface ReviewProfileDialogProps {
	open: boolean;
	fetching: boolean;
	issues?: ValidityIssue[];
	files?: FileRecord[];
	error?: string;
	finalize: boolean;
	processed: boolean;
	processing: boolean;
	onDismiss: () => void;
	onProceed: (ignoreWarnings?: boolean) => void;
}

function severityRank(issue: ValidityIssue): number {
	// in ascending terms, warnings are last
	return issue.severity === 'warning' ? 1 : 0;
}

export default function ReviewParticipantDialog(
	props: ReviewProfileDialogProps
) {
	const {
		files,
		issues,
		fetching,
		error,
		open,
		finalize,
		processed,
		processing,
		onDismiss,
		onProceed,
	} = props;

	const issuesExist = (issues ?? []).length > 0;

	const locationIssues: { [location: string]: ValidityIssue[] } = {};
	issues?.forEach((iss) => {
		let currIssues = locationIssues[iss.location];
		if (!currIssues) {
			currIssues = [];
			locationIssues[iss.location] = currIssues;
		}
		currIssues.push(iss);
	});
	Object.entries(locationIssues).forEach(([key, value]) => {
		locationIssues[key] = value.sort(
			(iss1, iss2) => severityRank(iss1) - severityRank(iss2)
		);
	});

	const [acknowledged, setAcknowledged] = React.useState(false);
	useLayoutEffect(() => setAcknowledged(false), [issues]);

	const mayProceed = useMemo(() => {
		if (Boolean(!files?.length) || !issues) return false;

		if (processed || !issuesExist) return true;

		return acknowledged;
	}, [processed, issuesExist, issues, acknowledged, files]);

	const classes = useStyles(props);

	const fileDirectory = React.useMemo(
		function initFileHierarchy() {
			if (!files) {
				return {};
			}
			if (files.length <= 0) {
				return {};
			}

			const untrackedIssues =
				issues?.filter(
					(iss) => !files.some((f) => f.filename.startsWith(iss.location))
				) ?? [];
			// HACK: _blank file inserted to render empty parent folders when they have issues
			const paddedFiles = files.concat(
				untrackedIssues.map((i) => ({
					filename: i.location.concat('/_blank'),
					modified: '',
					size: 0,
					issues: [],
					status: 'processed',
					delivered: null,
				}))
			);

			var folder: Array<any> = [];

			paddedFiles.forEach((file) => {
				const pathComponentsReversed = file.filename
					.concat(` (${handleFileSize(file.size)})`)
					.split('/')
					.reverse();
				folder.push(
					pathComponentsReversed
						.slice(0, -1)
						.reduce((previous: any, current: string) => {
							if (Object.entries(previous).length === 0) {
								return { [current]: file };
							}
							return { [current]: { ...previous } };
						}, {})
				);
			});

			return folder.reduce((prev, curr) => {
				return mergeDeep(prev, [curr]);
			}, {});
		},
		[files, issues]
	);

	const errorMessage = useMemo(() => {
		if (fetching || !issues) return null;

		if (error) return error;

		if (!mayProceed) {
			// Explain why cannot proceed
			if (files?.length === 0) {
				return 'No participant files found, cannot proceed';
			}
		}

		return null;
	}, [error, mayProceed, issues, fetching, files?.length]);

	const topFolderKey = Object.keys(fileDirectory)[0];

	const mustAcknowledge = issuesExist && files && !processed;
	return (
		<Dialog
			open={open}
			onClose={onDismiss}
			classes={{
				paper: classes.dialogPaper,
			}}
		>
			<DialogTitle>Review Submission</DialogTitle>
			<DialogContent className={classes.container} dividers>
				<Typography className={classes.folderTitle}>
					Study Folder View
				</Typography>
				<span>
					{fetching ? (
						'Loading...'
					) : files && files.length ? (
						<div className={classes.studyFolder}>
							<FileTree
								filename={topFolderKey}
								directory={fileDirectory[topFolderKey]}
								folderIssues={locationIssues}
							></FileTree>
						</div>
					) : (
						'There are no files to display.'
					)}
				</span>
			</DialogContent>

			{errorMessage && (
				<Typography className={`${classes.message} ${classes.providedError}`}>
					{errorMessage}
				</Typography>
			)}

			{processed && (
				<Typography className={classes.message}>
					Updated participant record
				</Typography>
			)}

			<DialogActions>
				{mustAcknowledge && (
					<label className={classes.text}>
						Acknowledge Warnings
						<Checkbox
							color="primary"
							onChange={() => setAcknowledged(!acknowledged)}
						/>
					</label>
				)}
				{!processed && (
					<DoneButton
						processing={processing}
						processed={processed}
						onDismiss={onDismiss}
						onProceed={() => onProceed(acknowledged)}
						mayProceed={mayProceed}
						finalize={finalize}
					/>
				)}
			</DialogActions>
		</Dialog>
	);
}

function DoneButton({
	processing,
	processed,
	onDismiss,
	onProceed,
	mayProceed,
	finalize,
}: {
	finalize: boolean;
	processed: boolean;
	processing: boolean;
	onDismiss: () => void;
	onProceed: () => void;
	mayProceed: boolean | 0;
}) {
	const actionText = useMemo(() => {
		if (processing) {
			return finalize ? 'Finalizing...' : 'Sending...';
		}

		if (processed) {
			return 'Done';
		}
		return finalize ? 'Finalize' : 'Send';
	}, [processing, finalize, processed]);

	return (
		<>
			<Button
				disabled={processing}
				variant="outlined"
				color="primary"
				onClick={onDismiss}
			>
				Cancel
			</Button>

			<Button
				variant="contained"
				color="primary"
				onClick={() => (processed ? onDismiss() : onProceed())}
				disabled={processing || !mayProceed}
			>
				{actionText}
			</Button>
		</>
	);
}
