import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';

interface DeviceDetailItemProps {
	attribute: string;
	value: string | JSX.Element;
	action?: JSX.Element;
}

export default function DeviceDetailItem(props: DeviceDetailItemProps) {
	const theme = useTheme();

	const wrapperStyle = { marginLeft: theme.spacing(3) };

	return (
		<div style={{ marginBottom: theme.spacing(1) }}>
			<Typography variant="h5" style={{ marginBottom: theme.spacing(0.5) }}>
				{props.attribute}
			</Typography>
			<Box display="flex" justifyContent="space-between">
				{typeof props.value === 'string' ? (
					<Typography variant="body2" style={wrapperStyle}>
						{props.value}
					</Typography>
				) : (
					<div style={wrapperStyle}>{props.value}</div>
				)}
				<div style={{ marginTop: -theme.spacing(1) }}>{props.action}</div>
			</Box>
		</div>
	);
}
