import { Box, Grid, Button, makeStyles, Theme, Typography, useTheme, lighten} from '@material-ui/core';
import { DateTime } from 'luxon';
import { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { FetchError, ParticipantListing, StudyContext,  } from '../types';
import UploadDialog from './deviceDetails/UploadDialog';
import MessagePage from './MessagePage';
import ParticipantSummaryList from './StudySummaryParticipants';
import DetailsSectionHeader from './DetailsSectionHeader';
import * as d3 from 'd3';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 0,
		width: '100%',
		flexDirection: 'row',
		borderRight: '1px solid rgba(0, 0, 0, 0.12);',
	},
	studyTitle: {
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(2),
	},
	panel: {
		display: 'flex',
		padding: theme.spacing(2, 0),
		alignItems: 'center',
		justifyContent: 'center',
	},
	uploadTime: {
		marginTop: -theme.spacing(1),
		textAlign: 'left',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: theme.spacing(2),
	},
	row: {
		flexGrow: 1,
	},
	horizontalPadding: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1,
	},
	chartWrapper: {
		alignSelf: 'center',
		position: 'relative',
	},
	placeholder: {
		alignSelf: 'center',
	},
	placeholderWrapper: { justifyContent: 'center', display: 'flex', height },
}));

interface StudySummaryProps {
	studyId: string;
	onSyncParticipants: () => void;
	syncing: boolean;
	error?: FetchError;
	participants?: ParticipantListing[];
	includeFinalized: boolean;
	onChangeFilter(includeFinalized: boolean): void;
}

const width = 400,
	height = 150,
	margin = 10,
	radius = Math.min(width, height) / 2 - margin;

export default function StudySummary(props: StudySummaryProps) {
	const { studyId, participants} = props;
	const [uploading, setUploading] = useState<string | null>(null);

	const theme = useTheme();
	const classes = useStyles();
	const handleDismissUpload = useCallback(() => setUploading(null), []);

	const chartRef = useRef<HTMLDivElement>(null);
	const activeColor = lighten(theme.palette.primary.light, 0.5);
	const completeColor = theme.palette.primary.main;
	const droppedColor = theme.palette.divider;

	const handleUploaded = useCallback(() => {}, []);
	const handleUpload = () => {
		setUploading(studyId);
	};

	const study = useContext(StudyContext);

	useEffect(
		function drawChart() {
			if (!study) {
				return;
			}
			const numRemovedParticipants = study.numRemovedParticipants;
			const numActiveParticipants = study.numActiveParticipants;
			const numFinalizedParticipants = study.numFinalizedParticipants;

			const chartWrapper = chartRef.current!;
			const svg = d3
				.select(chartWrapper)
				.append('svg')
				.attr('width', width)
				.attr('height', height);

			const pieChart = svg
				.append('g')
				.attr('transform', `translate(${width / 4},${height / 2})`);

			const data = [numFinalizedParticipants, numActiveParticipants, numRemovedParticipants];

			function color(i: number) {
				return i === 0 ? completeColor : (i === 1 ? activeColor : droppedColor);
			}

			// Chart
			const data_ready = d3.pie().sort(null)(data);

			const u = pieChart.selectAll('path').data(data_ready);

			u.enter()
				.append('path')
				// @ts-ignore types are fine
				.merge(u)
				.transition()
				.duration(250)
				.attr(
					'd',
					// @ts-ignore come on, there's nothing wrong here!
					d3
						.arc()
						.innerRadius(() => (study.completed ? 0 : radius * 0.618))
						.outerRadius(radius)
				)
				.attr('fill', function (d, i) {
					return color(i);
				})
				.style('opacity', 1);

			u.exit().remove();

			// Legend
			const legend = svg.append('g').attr('transform', `translate(${180}, 10)`);
			legend
				.append("text")
				.text(`Total Participants: ${numActiveParticipants + numFinalizedParticipants + numRemovedParticipants}`)
				.attr('x', width / 7)
				.attr('y', 20)
			// Add one dot in the legend for each name.
			legend
				.selectAll('mydots')
				.data(data)
				.enter()
				.append('rect').attr("width", 10).attr("height", 10) //
				.attr('x', width / 7)
				.attr('y', function (d, i) {
					return 45 + i * 25;
				})
				.style('fill', function (d, i) {
					return color(i);
				});

			// Add one dot in the legend for each name.
			legend
				.selectAll('mylabels')
				.data(data)
				.enter()
				.append('text')
				.attr('x', (width / 7) + 15)
				.attr('y', function (d, i) {
					return 50 + i * 25;
				})
				.style('fill', 'black')
				.text(function (d, i) {
					return i === 0 ? 'Complete: ' + String(d)  : (i === 1 ? 'Active: ' + String(d) : 'Dropped: ' + String(d));
				})
				.attr('text-anchor', 'left')
				.style('alignment-baseline', 'middle');

			return () => {
				if (chartWrapper) {
					chartWrapper.innerHTML = '';
				}
			}
		},
		[study, activeColor, completeColor, droppedColor]
	);
	
	if (!study) return <MessagePage title="Loading..." message="please wait" />;

	const { labwork, name: studyName } = study;

	const cytokineUploadTime = labwork?.find(
		(details) => details.panel === 'Cytokines'
	)?.last_upload;
	const hormoneUploadTime = labwork?.find(
		(details) => details.panel === 'Hormones'
	)?.last_upload;

	return (
		<Box flex={1} pt={2} alignItems="center" overflow="auto">
			<Box className={classes.header}>
				<Typography
					className={classes.studyTitle}
					variant="h3"
					color="textSecondary"
				>
					{studyName}
				</Typography>
			</Box>

			<Grid container direction="row" className={classes.row}>
				<Grid item xs>
					<Box className={classes.root}>
						<DetailsSectionHeader title="Summary"/>
						<Box className={classes.panel}>
							<div ref={chartRef} className={classes.chartWrapper} />
							{!participants && (
								<div className={classes.placeholderWrapper}>
									<Typography className={classes.placeholder}>
										No participants recruited yet
									</Typography>
								</div>
							)}
						</Box>
					</Box>
				</Grid>
				{labwork && (
					<Grid item xs>
						<Box className={classes.root}>
							<DetailsSectionHeader title="Blood Panels" />
							<Box className={classes.panel}>
								<div className={classes.horizontalPadding}>
									<div>
										<Typography className={classes.uploadTime}>
											Cytokines
										</Typography>
										<Typography
											className={classes.uploadTime}
											variant="subtitle2"
											color="textSecondary"
										>
											{cytokineUploadTime
												? DateTime.fromISO(cytokineUploadTime).toRelative()
												: 'Not Uploaded'}
										</Typography>
									</div>
								</div>
								<div className={classes.horizontalPadding}>
									<div>
										<Typography className={classes.uploadTime}>
											Hormones
										</Typography>
										<Typography
											className={classes.uploadTime}
											variant="subtitle2"
											color="textSecondary"
										>
											{hormoneUploadTime
												? DateTime.fromISO(hormoneUploadTime).toRelative()
												: 'Not Uploaded'}
										</Typography>
									</div>
								</div>
								<div className={classes.horizontalPadding}>
									<Button
										variant="outlined"
										color="primary"
										onClick={handleUpload}
									>
										Upload
									</Button>
								</div>
							</Box>
						</Box>
					
						<UploadDialog
							message=""
							deviceType="TAMU_Labwork"
							studyId={studyId}
							open={uploading !== null}
							onDismiss={handleDismissUpload}
							onSuccessfullyUploaded={handleUploaded}
						/>
					</Grid>
				)}
			</Grid>
			
			<DetailsSectionHeader
				title="Participants"
				action={{
					title: 'Sync',
					action: props.onSyncParticipants,
				}}
				actionDisabled={props.syncing}
			/>
			<ParticipantSummaryList
				participants={participants}
				error={props.error}
				includeFinalized={props.includeFinalized}
				onChangeFilter={props.onChangeFilter}
			/>
		</Box>
	);
}
