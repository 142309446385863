import * as AppleID from 'appleid.auth';
import React, { useEffect } from 'react';

// FIXME: This needs to be based on an environment variable
const REDIRECT_URL = process.env.REACT_APP_SDM_OAUTH_REDIRECT_URL!;

type AppleSignInButtonProps = Partial<AppleID.auth.RenderConfig> & {
	onSuccess: (token: AppleID.auth.SignInResponse) => void;
	onFailure: (error: AppleID.auth.SignInError) => void;
};

export default function AppleSignInButton({
	width = 210,
	height = 40,
	onSuccess,
	onFailure,
	...renderProps
}: AppleSignInButtonProps) {
	useEffect(() => {
		AppleID.auth.renderButton({
			...{
				width,
				height,
			},
			...renderProps,
		});

		// NOTE: Calling `AppleID.auth.signIn(clientConfig)` in an onClick handler seems like it would be cleaner
		//       than using `AppleID.auth.init(clientConfig)` and listening for global auth success/failure events,
		//       but `AppleID.auth.renderButton()` attaches its own 'click' listener to the button element,
		//       and we don't want to trigger multiple auth events.
		//
		//       Moreover, that 'click' listener throws an error if `AppleID.auth.init(clientConfig)` hasn't been called,
		//       so we might as well play along to avoid spewing any spurious errors to the console.
		//
		//       The only way to use the desired Promise returned by `AppleID.auth.signIn(clientConfig)` would be to
		//       render our own button so we can attach (only) our own listener.
		AppleID.auth.init({
			clientId: 'edu.tamu.tcat.sdm.services',
			redirectURI: REDIRECT_URL,
			scope: 'openid',
			usePopup: true,
		});
		document.addEventListener<any>('AppleIDSignInOnSuccess', onSuccess);
		document.addEventListener<any>('AppleIDSignInOnFailure', onFailure);

		return () => {
			document.removeEventListener<any>('AppleIDSignInOnSuccess', onSuccess);
			document.removeEventListener<any>('AppleIDSignInOnFailure', onFailure);
		};
	}, [width, height, onSuccess, onFailure, renderProps]);

	return (
		<div
			id="appleid-signin"
			style={{
				width,
				height,
				cursor: 'pointer',
			}}
		/>
	);
}
