import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Theme,
	Typography,
} from '@material-ui/core';
import Dropzone from 'dropzone';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import React, {
	MutableRefObject,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { baseUri, getHeaders } from '../../api';
import { StudyContext } from '../../types';
import DropzoneReact from '../DropzoneReact';

const OBS_DATE_STRING_FORMAT: DateTimeFormatOptions = {
	weekday: 'long',
	day: 'numeric',
	month: 'long',
} as const;

const useStyles = makeStyles((theme: Theme) => ({
	dialogPaper: {
		minHeight: 300,
		width: theme.breakpoints.values.md,
	},
	message: {
		marginBottom: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(2),
		minWidth: '40%',
	},
}));

interface UploadDialogProps {
	studyId?: string;
	participantId?: string;
	deviceType?: string;
	open: boolean;
	message?: string;
	observations?: string[];
	extensions?: string[];
	uploading?: boolean;
	onDismiss: () => void;
	onSuccessfullyUploaded: (deviceType: string) => void;
}

function protectUploadListener(event: BeforeUnloadEvent) {
	event.preventDefault();
	return 'File upload in progress. Leaving will cancel upload';
}

function removeProtectUploadListener() {
	window.removeEventListener('beforeunload', protectUploadListener, {
		capture: true,
	});
}

export default function UploadDialog({
	studyId,
	participantId,
	deviceType,
	open,
	message,
	observations,
	extensions,
	onDismiss,
	onSuccessfullyUploaded,
}: UploadDialogProps) {
	const classes = useStyles();
	const dropzoneRef: MutableRefObject<Dropzone | undefined> =
		useRef<Dropzone>();

	const [dropzoneReady, setDropzoneReady] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [canUpload, setCanUpload] = useState(false);

	// TODO: clean this component tree up now that solution is available
	const handleQueueFinish = useCallback(
		function () {
			console.log('Finished processing queue');
			setUploading(false);
			setCanUpload(false);
			onSuccessfullyUploaded(deviceType!);
			const dropzone = dropzoneRef.current!;
			dropzone.off('queuecomplete');
			dropzone.options.autoProcessQueue = false;

			// Release event listener blocking leave
			removeProtectUploadListener();
		},
		[deviceType, onSuccessfullyUploaded]
	);

	const handleQueueStart = useCallback(
		function () {
			if (!dropzoneReady) return;

			const dropzone = dropzoneRef.current;
			if (dropzone) {
				setUploading(true);
				dropzone.processQueue();
				dropzone.options.autoProcessQueue = true;
				dropzone.on('queuecomplete', handleQueueFinish);
			}

			// Add event listener to block accidental leaving
			window.addEventListener('beforeunload', protectUploadListener, {
				capture: true,
			});

			console.log('Started processing queue');
		},
		[dropzoneReady, handleQueueFinish]
	);

	const handleAddedFile = useCallback(
		(f: Dropzone.DropzoneFile) => {
			if (!deviceType) {
				setCanUpload(false);
				return;
			}

			setCanUpload(true);

			// Ensure we're not duplicating the queue
			const dropzone = dropzoneRef.current!;
			const matchingFileIndex = dropzone.files
				.slice(0, -1)
				.findIndex((queuedFile) => {
					return queuedFile.name === f.name;
				});
			if (matchingFileIndex >= 0) {
				dropzone.removeFile(dropzone.files[matchingFileIndex]);
			}
		},
		[deviceType]
	);

	const studyCxt = useContext(StudyContext);
	const deviceName = useMemo(() => {
		if (!deviceType) return undefined;

		return studyCxt!.devices.find((d) => d.device_type === deviceType)?.name;
	}, [studyCxt, deviceType]);

	const isOpen = open && studyId !== undefined;

	useEffect(() => {
		if (!isOpen) setDropzoneReady(false);
	}, [isOpen]);

	const handleDismiss = useCallback(() => {
		if (!uploading) {
			onDismiss();
			return;
		}
		if (!window.confirm('Closing will cancel uploads in progress. Proceed?'))
			return;
		setUploading(false);
		setCanUpload(false);
		onDismiss();
		removeProtectUploadListener();
	}, [onDismiss, uploading]);

	// Set up observation date handling

	const observationOptions = useMemo(() => {
		const obsDateMap = observations
			?.filter((d) => d.length > 0)
			.map((d) => d.slice(0, 10))
			.reduce((arr, curr) => ({ ...arr, [curr]: true }), {});

		return (
			obsDateMap &&
			Object.keys(obsDateMap)
				.sort()
				.reverse()
				.map((d) => [d, DateTime.fromISO(d)] as const)
		);
	}, [observations]);

	const [selectedObservation, setSelectedObservation] = useState<string>('');

	useLayoutEffect(
		function chooseDefaultObservation() {
			const today = DateTime.now().endOf('day');
			// walk from last observation to earlier
			const mostRecentObservation = observationOptions?.find(
				([_, date]) => date < today
			);

			setSelectedObservation(mostRecentObservation?.[0] ?? '');
		},
		[observationOptions]
	);

	const handleObsDateChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setSelectedObservation(event.target.value as string);
		dropzoneRef.current?.removeAllFiles();
		setCanUpload(false);
	};

	const dropzone = useMemo(
		() =>
			isOpen ? (
				<DropzoneReact
					dropzoneRef={dropzoneRef}
					dropzoneOptions={{
						url: !participantId
							? `${baseUri}/v1/studies/${studyId}/import`
							: `${baseUri}/v1/studies/${studyId}/${participantId}/devices/${deviceType}?obsDate=${selectedObservation}`,
						uploadMultiple: true,
						headers: getHeaders(),
						autoProcessQueue: false,
						parallelUploads: 4,
						queuecomplete: handleQueueFinish,
						maxFilesize: undefined,
						init(this: Dropzone) {
							this.on('addedfile', handleAddedFile);
							dropzoneRef.current = this;
						},
					}}
					extensions={extensions}
					onReady={() => setDropzoneReady(true)}
				/>
			) : null,
		[
			isOpen,
			studyId,
			participantId,
			deviceType,
			extensions,
			selectedObservation,
			handleAddedFile,
			handleQueueFinish,
		]
	);

	return (
		<Dialog
			onClose={handleDismiss}
			open={isOpen}
			TransitionProps={{
				mountOnEnter: true,
				unmountOnExit: true,
			}}
			classes={{
				paper: classes.dialogPaper,
			}}
		>
			<DialogTitle>Upload {deviceName} Files</DialogTitle>
			<DialogContent dividers>
				{message && (
					<Typography variant="body2" className={classes.message}>
						{message}
					</Typography>
				)}

				{observations && (
					<FormControl
						disabled={uploading}
						variant="standard"
						className={classes.formControl}
					>
						<InputLabel id="obs-select-label">Observation Date</InputLabel>
						<Select
							labelId="obs-select-label"
							id="obs-select"
							value={selectedObservation}
							onChange={handleObsDateChange}
						>
							{observationOptions!.map(([isoDate, luxonDt]) => (
								<MenuItem key={isoDate} value={isoDate}>
									{luxonDt.toLocaleString(OBS_DATE_STRING_FORMAT)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}

				{dropzone}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={handleDismiss}>
					{uploading ? 'Cancel' : 'Close'}
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={uploading || !canUpload}
					onClick={handleQueueStart}
				>
					Upload
				</Button>
			</DialogActions>
		</Dialog>
	);
}
