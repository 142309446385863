import { DeviceRenderStrategy } from '../../../types';

export const ErrorDeviceRenderStrategy = (msg: JSX.Element) =>
	({
		title: 'Error',
		renderHeader: () => msg,
		showCoverage: false,
	} as DeviceRenderStrategy);

export default ErrorDeviceRenderStrategy;
