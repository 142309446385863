import {
	Box,
	Breadcrumbs,
	Button,
	Container, 
	makeStyles,
	Theme, 
	Typography,
	useTheme
} from '@material-ui/core';
import produce from 'immer';
import { DateTime } from 'luxon';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState
} from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import {
	deleteFile,
	fetchDeviceDetails,
	fetchParticipantDetails,
	ingestCloudData,
	reprocessFile,
	syncStudyParticipants
} from '../../api';
import ReviewProfileWrapper from '../../components/deviceDetails/review/ReviewProfileWrapper';
import {
	DeviceMeta,
	DeviceRegistry,
	FetchError,
	FetchResult,
	NetworkDeviceDetails,
	ParticipantDetails,
	RedcapData,
	StudyContext,
	StudyDetails
} from '../../types';
import DeviceDetailsWrapper from '../deviceDetails/DeviceDetailsWrapper';
import { getAcceptedExtensions } from '../deviceDetails/renderStrategies/deviceHandlingRegistry';
import UploadDialog from '../deviceDetails/UploadDialog';
import DeviceInstructions from '../DeviceInstructions';
import MessagePage from '../MessagePage';
import ParticipantDeviceList from '../ParticipantDeviceList';
import RedcapFormList from '../RedcapFormList';

type RegistryAction =
	| { type: 'reset' }
	| { type: 'read_participant_devices'; devices: DeviceMeta[] }
	| { type: 'sync/request'; device_type: string }
	| { type: 'sync/success'; device_type: string }
	| { type: 'sync/fail'; device_type: string; error: FetchError }
	| { type: 'reprocess/request'; device_type: string }
	| { type: 'reprocess/success'; device_type: string }
	| { type: 'reprocess/fail'; device_type: string; error: FetchError }
	| { type: 'delete/request'; device_type: string }
	| { type: 'delete/success'; device_type: string; file: string }
	| { type: 'delete/fail'; device_type: string; error: FetchError }
	| { type: 'fetch/request'; device_type: string }
	| {
			type: 'fetch/success';
			device_type: string;
			device_info: NetworkDeviceDetails;
	  }
	| { type: 'fetch/fail'; device_type: string; error: FetchError }
	| { type: 'upload/success'; device_type: string };

const useStyles = makeStyles((theme: Theme) => ({
	linkWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	link: {
		fontWeight: 'bold',
		textDecoration: 'none',
	},
}));
function deviceInfoReducer(state: DeviceRegistry, action: RegistryAction) {
	switch (action.type) {
		case 'reset':
			return {};
		case 'read_participant_devices':
			return produce(state, (draft) => {
				action.devices.forEach((meta) => {
					const regVal = draft[meta.device_type];
					if (regVal) {
						regVal.last_submit = meta.last_submit;
						regVal.issue_severity = meta.issue_severity;
					} else {
						draft[meta.device_type] = {
							status: 'idle',
							last_submit: meta.last_submit,
							issue_severity: meta.issue_severity,
						};
					}
				});
			});
		case 'fetch/request':
			return produce(state, (draft) => {
				draft[action.device_type].status = 'fetching';
			});
		case 'fetch/success':
			return produce(state, (draft) => {
				const regItem = draft[action.device_type];
				regItem.data = action.device_info;
				regItem.status = 'idle';
				regItem.error = undefined;
				let issueSeverity = Math.max(
					...action.device_info.issues.map((d) =>
						d.severity === 'error' ? 2 : 1
					)
				);
				if (issueSeverity === 0) {
					issueSeverity = action.device_info.files.some((f) => f.issues?.length)
						? 1
						: 0;
				}
				regItem.issue_severity = issueSeverity;
				regItem.last_submit = action.device_info.files.reduce(
					(max, f) => (max.localeCompare(f.modified) < 0 ? f.modified : max),
					''
				);
			});
		case 'fetch/fail':
			return produce(state, (draft) => {
				const regItem = draft[action.device_type];
				regItem.error = action.error;
				regItem.status = 'idle';
			});
		case 'sync/request':
			return produce(state, (draft) => {
				draft[action.device_type].status = 'ingesting';
			});
		case 'sync/success':
			return produce(state, (draft) => {
				const regItem = draft[action.device_type];
				regItem.status = 'idle';
				regItem.error = undefined;
			});
		case 'sync/fail':
			return produce(state, (draft) => {
				const regItem = draft[action.device_type];
				regItem.status = 'idle';
				regItem.error = action.error;
			});
		// TODO: Improve visibility of files being reprocessed
		case 'reprocess/request':
			break;
		case 'reprocess/success':
			break;
		case 'reprocess/fail':
			break;
		case 'delete/request':
			break;
		case 'delete/success':
			break;
		case 'delete/fail':
			break;
		case 'upload/success':
			return produce(state, (draft) => {
				const regItem = draft[action.device_type];
				regItem.last_submit = DateTime.local().toISO();
			});
		default:
			return state;
	}
}

function getSequencedObservationDates(
	redcapData: { [redcap_var: string]: string },
	obsDates: string[]
): string[] {
	return obsDates
		.map((obsDate) => redcapData[obsDate])
		.filter((d) => d !== undefined)
		.sort();
}

type ParticipantDetailsScreensProps = {
	onRemovedParticipant: (participantId: string) => void;
};

export default function ParticipantDetailsScreen(
	props: ParticipantDetailsScreensProps
) {
	const [participantInfo, setParticipantInfo] = useState<ParticipantDetails>();
	const { studyId, participantId } = useParams<{
		studyId: string;
		participantId: string;
	}>() as { studyId: string; participantId: string };

	const [participantFetchError, setParticipantFetchError] =
		useState<FetchError | undefined>();

	const [deviceRegistry, regDispatch] = useReducer(
		produce(deviceInfoReducer),
		{}
	);

	const [selectedDevice, setSelectedDevice] = useState<string | undefined>();
	const [instructDevice, setInstructDevice] = useState<string | undefined>();
	const [uploading, setUploading] = useState<string | null>(null);

	const abortController = useRef<AbortController>();

	useEffect(
		function resetDeviceData() {
			abortController.current?.abort();
			regDispatch({ type: 'reset' });
		},
		[studyId, participantId]
	);
	const ingestParticipantData = useRef(function ingestParticipantData([
		payload,
		error,
	]: [null, FetchError] | [ParticipantDetails, null]): void {
		if (!error) {
			setParticipantInfo(payload!);
			// Prepare the device registry
			regDispatch({
				type: 'read_participant_devices',
				devices: payload!.devices,
			});
			setParticipantFetchError(undefined);
		} else {
			setParticipantFetchError(error);
		}
	});

	useEffect(
		function loadParticipant() {
			let promise: FetchResult<ParticipantDetails>;
			[promise, abortController.current] = fetchParticipantDetails(
				studyId,
				participantId
			);
			promise.then(ingestParticipantData.current!);
		},
		[studyId, participantId]
	);

	const theme = useTheme();

	const studyCxt = useContext(StudyContext);

	const messageElement: JSX.Element | null = useMemo(
		() =>
			MessageElement({
				error: participantFetchError,
				studyCxt,
				participantInfo,
			}),
		[studyCxt, participantFetchError, participantInfo]
	);

	const redcap_fields = useMemo(() => {
		if (!participantInfo) return {};

		return participantInfo.redcap_fields
			? participantInfo.redcap_fields
			: participantInfo.redcap_events!;
	}, [participantInfo]);
	const obs_fields = studyCxt?.config.observation_fields;

	const canIngest = useMemo(() => {
		if (!obs_fields || !redcap_fields) return false;
		const orderedObservations = Array.isArray(redcap_fields)
			? redcap_fields
					.flatMap((instrument) =>
						getSequencedObservationDates(instrument, obs_fields)
					)
					.sort()
			: getSequencedObservationDates(redcap_fields, obs_fields);

		return orderedObservations.length > 0;
	}, [obs_fields, redcap_fields]);

	const handleFetch = useCallback(
		function (device_type: string) {
			regDispatch({ type: 'fetch/request', device_type });
			let promise: FetchResult<NetworkDeviceDetails>;
			[promise, abortController.current] = fetchDeviceDetails(
				studyId,
				participantId,
				device_type
			);
			const amendedPromise = promise.then(function handleFetchedMeta([
				payload,
				error,
			]: [NetworkDeviceDetails, null] | [null, FetchError]) {
				if (!error) {
					regDispatch({
						type: 'fetch/success',
						device_type,
						device_info: payload!,
					});
				} else if (error.status === -1) {
					// Aborted likely due to navigation, ignore
					return;
				} else {
					regDispatch({ type: 'fetch/fail', device_type, error: error });
					alert(`Problem fetching device details: ${error.error}`);
				}
			});
			return [amendedPromise] as const;
		},
		[participantId, studyId]
	);

	const doCloudSync = useCallback(
		function (device_type: string) {
			let promise: Promise<any>;

			regDispatch({ type: 'sync/request', device_type });
			[promise, abortController.current] = ingestCloudData({
				studyId,
				participantId,
				deviceType: device_type,
			});
			promise.then(function checkIngestResults(
				res: Awaited<FetchResult<string>>
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [_, error] = res;
				if (!error) {
					regDispatch({ type: 'sync/success', device_type });
					[promise] = handleFetch(device_type);
					return promise;
				} else if (error.status === -1) {
					// Aborted likely due to navigation, ignore
					return;
				} else {
					const fetchError: FetchError = error;
					if (fetchError.status === 422) {
						alert(fetchError.error);
					}
					regDispatch({ type: 'sync/fail', device_type, error });
					return [null, error];
				}
			});
		},
		[handleFetch, participantId, studyId]
	);

	const handleIngest = useCallback(
		function ingestDeviceData(device_type: string, upload = false) {
			if (!device_type) return;

			if (!upload) {
				return doCloudSync(device_type);
			} else {
				// Start file upload
				setUploading(device_type);
			}
		},
		[doCloudSync]
	);

	const handleReprocess = useCallback(
		function requestReprocessFile(keyPath: string) {
			if (!selectedDevice) {
				return Promise.reject(
					"Can't reprocess file without a device selection"
				);
			}

			regDispatch({ type: 'reprocess/request', device_type: selectedDevice });
			let promise: Promise<any>;

			[promise, abortController.current] = reprocessFile({
				studyId,
				participantId,
				deviceType: selectedDevice,
				rawKey: keyPath,
			});
			const rv = promise.then(function checkReprocessResults(
				res: Awaited<FetchResult<undefined>>
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [_, error] = res;
				if (!error) {
					regDispatch({
						type: 'reprocess/success',
						device_type: selectedDevice,
					});
					return;
				} else if (error.status === -1) {
					// Aborted likely due to navigation, ignore
					return;
				} else {
					const fetchError: FetchError = error;
					if (fetchError.status === 422) {
						alert(fetchError.error);
					}
					regDispatch({
						type: 'reprocess/fail',
						device_type: selectedDevice,
						error,
					});
					return;
				}
			});

			return rv;
		},
		[studyId, participantId, selectedDevice]
	);

	const handleDelete = useCallback(
		function doDeleteFile(keyPath: string, confirmed: boolean) {
			if (!selectedDevice) {
				return Promise.reject(
					"Can't reprocess file without a device selection"
				);
			}

			if (
				!confirmed &&
				!window.confirm(
					`This will permanently remove '${keyPath.slice(
						keyPath.indexOf('/') + 1
					)}' from SDM. Are you sure you wish to proceed?`
				)
			) {
				return Promise.resolve();
			}

			// TODO: Do the deletion
			regDispatch({ type: 'delete/request', device_type: selectedDevice });
			let promise: Promise<any>;

			[promise, abortController.current] = deleteFile({
				studyId,
				participantId,
				deviceType: selectedDevice,
				rawKey: keyPath,
			});
			return promise.then(function checkReprocessResults(
				res: Awaited<FetchResult<undefined>>
			) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [_, error] = res;
				if (!error) {
					regDispatch({
						type: 'delete/success',
						file: keyPath,
						device_type: selectedDevice,
					});
					return;
				} else if (error.status === -1) {
					// Aborted likely due to navigation, ignore
					return;
				} else {
					const fetchError: FetchError = error;
					if (fetchError.status === 422) {
						alert(fetchError.error);
					}
					regDispatch({
						type: 'delete/fail',
						device_type: selectedDevice,
						error,
					});
					return;
				}
			});
		},
		[studyId, participantId, selectedDevice]
	);

	const [syncingForms, setSyncingForms] = useState(false);

	const handleSync = useCallback(() => {
		setSyncingForms(true);
		let [promise, abort]: [FetchResult<any>, AbortController] =
			syncStudyParticipants(studyId);

		promise
			.then(() => {
				[promise, abort] = fetchParticipantDetails(studyId, participantId);
				return promise;
			})
			.then(ingestParticipantData.current!)
			.finally(() => setSyncingForms(false));

		return () => {
			abort.abort();
		};
	}, [studyId, participantId]);

	const handleCompleted = useCallback(() => {
		const { redcap_fields, redcap_events } = participantInfo!;
		setParticipantInfo({
			id: participantInfo!.id,
			devices: participantInfo!.devices,
			redcap_fields,
			redcap_events,
			completed: DateTime.local().toISO(),
			finalized: participantInfo!.finalized,
		});
	}, [participantInfo]);

	const handleFinalized = useCallback(() => {
		setParticipantInfo({
			id: participantId,
			devices: participantInfo!.devices,
			redcap_fields: participantInfo!.redcap_fields,
			redcap_events: participantInfo!.redcap_events,
			completed: participantInfo!.completed,
			finalized: DateTime.local().toISO(),
		});
	}, [participantId, participantInfo]);

	const handleUploaded = useCallback((deviceType: string): void => {
		regDispatch({
			type: 'upload/success',
			device_type: deviceType,
		});
	}, []);

	const studyDevices = studyCxt?.devices;
	const [deviceName, deviceInstructions] = useMemo(
		function getInstructions() {
			if (!instructDevice) return [undefined, undefined];
			const selectedDevice = studyDevices?.find(
				(d) => d.device_type === instructDevice
			);
			if (!selectedDevice) return [undefined, undefined];

			return [selectedDevice.name, selectedDevice.instructions];
		},
		[studyDevices, instructDevice]
	);

	const deviceConfig = useMemo(
		() => studyDevices?.find((d) => d.device_type === selectedDevice),
		[studyDevices, selectedDevice]
	);

	const redcapData =
		participantInfo?.redcap_fields ?? participantInfo?.redcap_events;

	const observationDates = useMemo(() => {
		if (!studyCxt || !redcapData) return [];

		const obsFields = studyCxt.config.observation_fields;
		if (!studyCxt.config.is_classic) {
			return extractObservationDates(obsFields, redcapData);
		} else {
			return (redcapData as RedcapData[]).flatMap((d) =>
				extractObservationDates(obsFields, d)
			);
		}
	}, [redcapData, studyCxt]);

	const handleDismissUpload = useCallback(() => setUploading(null), []);

	const handleDismissDeviceDetails = () => setSelectedDevice(undefined);

	const statusMessage = participantInfo?.finalized
		? `finalized ${DateTime.fromISO(participantInfo?.finalized).toLocaleString(
				DateTime.DATE_SHORT
		  )} at ${DateTime.fromISO(participantInfo?.finalized).toLocaleString(
				DateTime.TIME_SIMPLE
		  )}`
		: `sent ${DateTime.fromISO(participantInfo?.completed!).toLocaleString(
				DateTime.DATE_SHORT
		  )} at ${DateTime.fromISO(participantInfo?.completed!).toLocaleString(
				DateTime.TIME_SIMPLE
		  )}`;

	const { onRemovedParticipant } = props;
	const classes = useStyles();
	if (messageElement) return messageElement;
	return (
		<Box flex={1} pt={2} alignItems="center" overflow="auto">
			<Box
				flex={1}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box display="flex" ml={2}>
					<Breadcrumbs separator={<IoChevronForward />} aria-label="breadcrumb">
						<div className={classes.linkWrapper}>
							<Link to={`/studies/${studyId}`} className={classes.link}>
								<Typography variant="h3" color="primary">
									{studyCxt?.name}
								</Typography>
							</Link>
						</div>
						<div className={classes.linkWrapper}>
							<Typography variant="h3">
								{participantId}
								{(participantInfo?.finalized || participantInfo?.completed) && (
									<Typography
										style={{ paddingLeft: theme.spacing(1) }}
										variant="caption"
										color="textSecondary"
									>
										{statusMessage}
									</Typography>
								)}
							</Typography>
						</div>
					</Breadcrumbs>
				</Box>

				{participantInfo && (
					<ReviewProfileWrapper
						participantId={participantInfo.id}
						redcap_fields={redcap_fields}
						completed={participantInfo.completed ?? null}
						onCompleted={handleCompleted}
						onFinalized={handleFinalized}
						onRemoved={() => onRemovedParticipant(participantInfo.id)}
					/>
				)}
			</Box>

			<RedcapFormList
				participantCode={participantInfo?.id}
				pid={studyCxt?.pid}
				events={studyCxt?.events}
				redcapFields={redcapData}
				onSync={handleSync}
				busySyncing={syncingForms}
			/>

			<ParticipantDeviceList
				participantDevices={participantInfo!.devices}
				deviceRegistry={deviceRegistry}
				deviceProfiles={studyCxt?.devices ?? []}
				redcapFields={redcapData!}
				canIngest={canIngest}
				onIngest={handleIngest}
				onSelectDevice={setSelectedDevice}
				onShowInstructions={setInstructDevice}
			/>

			<Container maxWidth="md">
				<DeviceDetailsWrapper
					device_type={selectedDevice}
					status={
						selectedDevice ? deviceRegistry[selectedDevice].status : undefined
					}
					deviceDetails={
						selectedDevice ? deviceRegistry[selectedDevice].data : undefined
					}
					isClassic={studyCxt?.config.is_classic || false}
					redcapFields={redcapData}
					timeZone={studyCxt?.timezone ?? ''}
					onDismiss={handleDismissDeviceDetails}
					onFetch={handleFetch}
					onIngest={handleIngest}
					onReprocess={handleReprocess}
					onDelete={handleDelete}
					studyConfig={studyCxt?.config ?? {}}
					deviceConfig={deviceConfig?.config ?? {}}
				/>
				<DeviceInstructions
					device={deviceName}
					instructions={deviceInstructions}
					onDismiss={() => setInstructDevice(undefined)}
				/>
				<UploadDialog
					message=""
					open={uploading !== null}
					onDismiss={handleDismissUpload}
					studyId={studyId}
					participantId={participantId}
					deviceType={uploading ?? undefined}
					observations={observationDates}
					extensions={getAcceptedExtensions(uploading)}
					onSuccessfullyUploaded={handleUploaded}
				/>
			</Container>
		</Box>
	);
}

function extractObservationDates(
	obsFields: string[],
	redcapData: RedcapData | RedcapData[]
): string[] {
	return obsFields
		.map((field) => (redcapData as RedcapData)[field] as string)
		.filter((v) => v !== undefined && v.length > 0);
}

function MessageElement({
	error,
	studyCxt,
	participantInfo,
}: {
	error?: FetchError;
	studyCxt?: StudyDetails;
	participantInfo?: ParticipantDetails;
}): JSX.Element | null {
	if (!error && studyCxt && participantInfo) return null;
	if (error) {
		switch (error.status) {
			case -1:
				return (
					<MessagePage
						title="Network Unavailable"
						message="Please check your network connection and try again."
					/>
				);
			case 500:
				// TODO: Provide an actual support method.
				return (
					<MessagePage
						title="Server Problem"
						message="Please wait a moment and try again. If the problem persists please contact support."
					/>
				);
			case 403:
				return (
					<MessagePage
						title="Not Authorized"
						message="You do not have access to this study. Return to the study selection page and start again."
					/>
				);
			default:
				return (
					<MessagePage
						title="Unexpected error"
						message={`There was a problem loading your studies. Please refresh and try again. If the problem persists please contact support. Error code ${
							error!.status
						}.`}
						action={
							<Button variant="outlined" color="primary">
								Reload
							</Button>
						}
					/>
				);
		}
	}
	if (!studyCxt) {
		return (
			<MessagePage
				title="Cannot show study"
				message="Unable to find study information. Please try refreshing or reselect the study."
			/>
		);
	}
	if (!participantInfo) {
		return (
			<MessagePage
				title="Loading..."
				message="Retrieving participant info, please wait..."
			/>
		);
	}
	return null;
}
