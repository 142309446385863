import {
	Button,
	ButtonGroup,
	makeStyles,
	Menu,
	MenuItem,
	Snackbar,
	Theme,
	Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React, {
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useState
} from 'react';
import {
	IoCaretDown as CaretDownIcon,
	IoSend as SendIcon
} from 'react-icons/io5';
import {
	complete,
	finalize,
	remove,
	review,
	ReviewResults
} from '../../../api';
import { RedcapData, StudyContext, ValidityIssue } from '../../../types';
import {
	isolateValuesFromInstruments,
	VAR_NAME_DEFAULT_SENSOR_REMOVAL
} from '../renderStrategies/utils';
import ReviewProfileDialog from './ReviewProfileDialog';

const useStyles = makeStyles<Theme, ReviewProfileButtonProps>((theme) => ({
	button: { marginRight: theme.spacing(4) },
}));

interface ReviewProfileButtonProps {
	participantId: string;
	completed: string | null;
	onCompleted: () => void;
	onFinalized: () => void;
	onRemoved: () => void;
	redcap_fields: RedcapData | RedcapData[];
}

export default function ReviewProfileButton(props: ReviewProfileButtonProps) {
	const {
		participantId,
		// completed,
		redcap_fields,
		onCompleted,
		onFinalized,
		onRemoved,
	} = props;
	const {
		id: studyId,
		prefix,
		config,
		userPermissions,
	} = useContext(StudyContext)!;

	const classes = useStyles(props);

	const [fetching, setFetching] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [toast, setToast] = useState<JSX.Element | null>(null);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [reviewContents, setReviewContents] =
		useState<ReviewResults | null>(null);
	const [didProcess, setDidProcess] = useState(false);

	const [error, setError] = useState<string | null>(null);

	const [completeAction, setCompleteAction] =
		useState<'finalize' | 'send' | 'remove' | null>(null);
	const [secondaryActionMenuOpen, setSecondaryActionMenuOpen] =
		React.useState(false);

	useLayoutEffect(() => {
		setDidProcess(false);
	}, [studyId, participantId, dialogOpen]);

	function handleUntoast() {
		setToast(null);
	}

	const handleSelectAction = useCallback(
		function (action: 'send' | 'finalize' | 'remove') {
			setSecondaryActionMenuOpen(false);

			if (action === 'remove') {
				if (
					window.confirm(
						'This will permanently remove the participant and all their files. Proceed?'
					)
				) {
					// TODO: Execute the removal request
					const [remProm, abort] = remove(studyId, participantId);
					remProm.then(([_, error]) => {
						let toast: JSX.Element;
						if (error) {
							toast = (
								<Alert severity="error" onClose={handleUntoast}>
									Unable to remove {participantId}; {error.status}-{error}
								</Alert>
							);
						} else {
							toast = (
								<Alert severity="success" onClose={handleUntoast}>
									Removed participant {participantId}
								</Alert>
							);
							onRemoved();
						}
						setToast(
							<Snackbar open autoHideDuration={6000} onClose={handleUntoast}>
								{toast}
							</Snackbar>
						);
					});

					return () => {
						abort.abort();
					};
				}
				return;
			}

			setDialogOpen(true);

			setCompleteAction(action);
			setFetching(true);
			const [fetchPromise, abort] = review(studyId, participantId);
			fetchPromise.then(([revContents, error]) => {
				if (!error) {
					setReviewContents(revContents);
				} else {
					console.error(error);

					setToast(
						<Snackbar open autoHideDuration={6000} onClose={handleUntoast}>
							<Alert severity="error" onClose={handleUntoast}>
								Unable to send {participantId}; {error.status}-{error.error}
							</Alert>
						</Snackbar>
					);
					setDialogOpen(false);
				}

				setFetching(false);
			});

			return () => {
				abort.abort();
			};
		},
		[studyId, participantId, onRemoved]
	);

	const handleToggleSecondaryButtonActionMenu = React.useCallback(() => {
		setSecondaryActionMenuOpen(!secondaryActionMenuOpen);
	}, [secondaryActionMenuOpen]);

	const handleCloseSecondaryActionMenu = (
		event: React.MouseEvent<Document, MouseEvent>
	) => {
		if (
			secondaryActionMenuAnchorRef.current &&
			secondaryActionMenuAnchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}
		setSecondaryActionMenuOpen(false);
	};

	const handleComplete = useCallback(() => {
		setProcessing(true);
		const [promise, abort] = complete(studyId, participantId);
		promise.then(([_, error]) => {
			if (error) {
				console.error(error);
				setToast(
					<Snackbar open autoHideDuration={6000} onClose={handleUntoast}>
						<Alert severity="error" onClose={handleUntoast}>
							Unable to send {participantId}; {error.status}-{error.error}
						</Alert>
					</Snackbar>
				);
				setError(error.error);
			} else {
				setToast(
					<Snackbar open autoHideDuration={6000} onClose={handleUntoast}>
						<Alert severity="success" onClose={handleUntoast}>
							Participant {participantId} sent
						</Alert>
					</Snackbar>
				);
				setError(null);
				onCompleted();
				setDidProcess(true);
			}
			setProcessing(false);
		});

		return () => {
			abort.abort();
		};
	}, [studyId, participantId, onCompleted]);

	function handleDismiss() {
		setDialogOpen(false);
		setReviewContents(null);
		setProcessing(false);
		setFetching(false);
		setDidProcess(false);
		setError(null);
		setCompleteAction(null);
	}

	// Re-enable when finalize is reintroduced
	const handleFinalize = useCallback(
		(ignoreWarnings?: boolean) => {
			setProcessing(true);
			const [promise, abort] = finalize(studyId, participantId, ignoreWarnings);
			promise.then(([_, error]) => {
				if (error) {
					console.error(error);
					setToast(
						<Snackbar open autoHideDuration={6000} onClose={handleUntoast}>
							<Alert severity="error" onClose={handleUntoast}>
								Unable to finalize {participantId}; {error.status}-{error.error}
							</Alert>
						</Snackbar>
					);
					setError(error.error);
				} else {
					setToast(
						<Snackbar open autoHideDuration={6000} onClose={handleUntoast}>
							<Alert severity="success" onClose={handleUntoast}>
								Participant {participantId} finalized
							</Alert>
						</Snackbar>
					);
					setError(null);
					onFinalized();
				}
				setProcessing(false);
				setDidProcess(true);
			});

			return () => {
				abort.abort();
			};
		},
		[studyId, participantId, onFinalized]
	);
	// TODO: Make this dependent on state, or have separate buttons
	const formIssues = useMemo(() => {
		const isClassicStudy = Array.isArray(redcap_fields);
		const incompleteForms = (
			isClassicStudy
				? (redcap_fields as RedcapData[]).flatMap((instrument) =>
						Object.entries(instrument)
				  )
				: Object.entries(redcap_fields)
		).filter(([field, value]) => {
			if (!/_complete$/.test(field)) return false;
			return value !== '' && value !== '2';
		});
		const sensorTimes = isolateValuesFromInstruments(
			redcap_fields,
			config[VAR_NAME_DEFAULT_SENSOR_REMOVAL] as string
		);
		const duplicateSensorRemoval = sensorTimes.length > 1 ? true : false;
		return {
			incompleteForms: incompleteForms,
			duplicateSensorRemoval: duplicateSensorRemoval,
		};
	}, [redcap_fields, config]);
	const disabledReason: string | undefined = useMemo(() => {
		if (processing) return 'Processing, please wait...';

		const isClassicStudy = Array.isArray(redcap_fields);

		// Make this a check for finalize but not completion
		if (completeAction === 'finalize' && formIssues.incompleteForms.length > 0)
			return `Incomplete REDCap forms:
		${formIssues.incompleteForms
			.map(
				([key, val]) => `${key} (${val === '0' ? 'Incomplete' : 'Unverified'})`
			)
			.join(';\n')}`;

		const today = DateTime.now().startOf('day');
		const invalidObservations = config?.observation_fields
			?.map((f) => {
				const allRedcapFields = isClassicStudy
					? (redcap_fields as RedcapData[]).flatMap((instrument) =>
							Object.entries(instrument)
					  )
					: Object.entries(redcap_fields);
				const obsFields = allRedcapFields.filter(([key]) => key === f);

				for (const obsEntry of obsFields) {
					const obsDate = obsEntry[1];
					if (!obsDate) return undefined;

					if (obsDate.length === 0) {
						return 'Observation date(s) not set in REDCap';
					}

					if (DateTime.fromISO(obsDate) > today) {
						return 'Observation date(s) is in the future';
					}
				}

				return undefined;
			})
			.filter((d) => d !== undefined);
		if (invalidObservations?.length) return invalidObservations[0];

		return undefined;
	}, [
		processing,
		redcap_fields,
		formIssues,
		config?.observation_fields,
		completeAction,
	]);

	const secondaryActionMenuAnchorRef = React.useRef<HTMLDivElement>(null);

	const canFinalize = userPermissions.can_finalize;
	const canRemove = userPermissions.can_remove_participants;
	const showMenu = canFinalize || canRemove; // will shift over time as new actions are added

	// TODO: Need to rework finalize as a secondary (menu) button
	const button = React.useMemo(
		() => (
			<ButtonGroup
				variant="contained"
				color="primary"
				className={classes.button}
				ref={secondaryActionMenuAnchorRef}
				disabled={disabledReason !== undefined}
			>
				<Button
					variant="contained"
					color="primary"
					onClick={() => handleSelectAction('send')}
					endIcon={<SendIcon />}
				>
					Send
				</Button>

				{showMenu && (
					<Button
						size="small"
						aria-controls="split-menu"
						aria-haspopup="true"
						aria-expanded={secondaryActionMenuOpen}
						aria-label="select option"
						onClick={handleToggleSecondaryButtonActionMenu}
					>
						<CaretDownIcon />
					</Button>
				)}
			</ButtonGroup>
		),
		[
			disabledReason,
			classes,
			secondaryActionMenuOpen,
			showMenu,
			handleSelectAction,
			handleToggleSecondaryButtonActionMenu,
		]
	);

	const renderedIssues: ValidityIssue[] | undefined = useMemo(() => {
		if (!reviewContents) return undefined;

		const redcapIssues: ValidityIssue[] = [];
		if (formIssues.incompleteForms.length > 0) {
			redcapIssues.push({
				location: `processed/${prefix}/${participantId}/REDCap`,
				message: `Incomplete REDCap forms: 
				${formIssues.incompleteForms
					.map(
						([key, val]) =>
							`${key} (${val === '0' ? 'Incomplete' : 'Unverified'})`
					)
					.join(';\n')}`,
				severity: 'error' as const,
			});
		}

		if (formIssues.duplicateSensorRemoval) {
			redcapIssues.push({
				location: `processed/${prefix}/${participantId}/REDCap`,
				message:
					'Multiple sensor removal times detected. This may result in data omission',
				severity: 'error' as const,
			});
		}

		return redcapIssues.concat(reviewContents.issues);
	}, [reviewContents, formIssues, prefix, participantId]);

	return (
		<>
			{toast}
			{disabledReason ? (
				<Tooltip arrow title={disabledReason} placement="bottom-start">
					<div>{button}</div>
				</Tooltip>
			) : (
				button
			)}
			<Menu
				id="split-menu"
				anchorEl={secondaryActionMenuAnchorRef.current}
				keepMounted
				open={secondaryActionMenuOpen}
				onClose={handleCloseSecondaryActionMenu}
			>
				{canRemove && (
					<MenuItem onClick={() => handleSelectAction('remove')}>
						Remove
					</MenuItem>
				)}
				{canFinalize && (
					<MenuItem onClick={() => handleSelectAction('finalize')}>
						Finalize
					</MenuItem>
				)}
			</Menu>
			<ReviewProfileDialog
				open={dialogOpen}
				finalize={completeAction === 'finalize'}
				onDismiss={handleDismiss}
				onProceed={
					completeAction === 'finalize' ? handleFinalize : handleComplete
				}
				fetching={fetching}
				files={reviewContents?.files}
				issues={renderedIssues}
				processing={processing}
				processed={didProcess}
				error={error ?? undefined}
			/>
		</>
	);
}
