import { makeStyles, Theme } from '@material-ui/core';
import { IoOpenOutline as OpenIcon } from 'react-icons/io5';

type ExternalLinkProps = JSX.IntrinsicElements['a'] & {
	variant?: 'primary' | 'secondary';
};

const useStyles = makeStyles((theme: Theme) => ({
	link: (props: ExternalLinkProps) => {
		const isPrimary = props.variant !== 'secondary';
		return {
			color: isPrimary
				? theme.palette.primary.main
				: theme.palette.text.secondary,
			textDecoration: 'none',
			fontWeight: !isPrimary ? 'bold' : undefined,
			display: 'flex',
			alignItems: 'center',
			'&:hover': {
				color: isPrimary
					? theme.palette.primary.dark
					: theme.palette.primary.main,
			},
		};
	},
    icon: {
		marginLeft: theme.spacing(0.5),
        fontSize: 'inherit',
	},
}));

export default function ExternalLink(props: ExternalLinkProps) {
	const classes = useStyles(props);

	const { variant, className, children, ...rest } = props;

	return (
		<a
			rel={'noreferrer'}
			target="_blank"
			className={classes.link + ' ' + className}
			{...rest}
		>
			{children}

			<OpenIcon className={classes.icon} />
		</a>
	);
}
