import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function useSignInRedirects() {
	const navigate = useNavigate();
	const location = useLocation();

	return useCallback(() => {
		let target: string;
		const currentState: { from: string } | undefined = location.state as any;
		if (currentState?.from) {
			target = currentState.from;
		} else if (location.pathname !== '/login') {
			target = location.pathname;
		} else {
			target = '/';
		}
		navigate(target, { replace: true });
	}, [navigate, location]);
}

export default useSignInRedirects;
