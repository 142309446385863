import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface ClickableTextProps {
	children: string | null;
	onClick?: () => void;
	disabled?: boolean;
    secondary?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	typography: (props: ClickableTextProps) => ({
        display: 'inline-flex',
        fontWeight: props.secondary ? 400 : 600,
		color: theme.palette.text.secondary,
		cursor: 'pointer',
		'&:hover': {
			color: theme.palette.primary.main,
		},
	}),
	disabledTypography: {
        display: 'inline-flex',
        fontWeight: 'bold',
        cursor: 'not-allowed',
		color: theme.palette.text.disabled,
	},
}));

export default function ClickableText(props: ClickableTextProps) {
	const classes = useStyles(props);

	return (
		<Typography
			className={
				`${props.disabled ? classes.disabledTypography : classes.typography} ${props.className}`
			}
			onClick={props.disabled ? undefined : props.onClick}
		>
			{props.children}
		</Typography>
	);
}
