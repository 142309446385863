import React from "react";
import { Container, Typography } from "@material-ui/core";

interface MessagePageProps {
	title: string;
	message: string;
	action?: JSX.Element;
	abbreviated?: boolean
}

export default function MessagePage({
	title,
	message,
	action,
	abbreviated,
}: MessagePageProps) {
	return (
		<Container maxWidth="md" style={{marginTop: abbreviated ? 20 : 80,}}>
			<Typography variant="h4">{title}</Typography>

			<div style={{ height: 40 }} />

			<Typography variant="body1">{message}</Typography>

			{action}
		</Container>
	);
}
