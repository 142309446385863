import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	dialog: {},
	dialogPaper: {
		minHeight: 300,
		width: theme.breakpoints.values.md,
	},
	content: {
		marginTop: theme.spacing(-1),
	},
}));

interface DeviceInstructionsProps {
	device?: string;
	instructions?: string;
	onDismiss: () => void;
}

export default function DeviceInstructions({
	device,
	instructions,
	onDismiss,
}: DeviceInstructionsProps) {
	const classes = useStyles();

	const options: MarkdownToJSX.Options = {
		overrides: {
			h1: {
				component: Typography,
				props: {
					variant: 'h3',
				},
			},
			h2: {
				component: Typography,
				props: {
					variant: 'h4',
				},
			},
			h3: {
				component: Typography,
				props: {
					variant: 'h5',
				},
			},
			h4: {
				component: Typography,
				props: {
					variant: 'h6',
				},
			},
		},
	};

	return (
		<Dialog
			onClose={onDismiss}
			open={instructions !== undefined}
			className={classes.dialog}
			classes={{
				paper: classes.dialogPaper,
			}}
		>
			<DialogTitle>{device} Instructions</DialogTitle>
			<DialogContent dividers className={classes.content}>
				{instructions && <Markdown options={options}>{instructions}</Markdown>}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onDismiss}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
