import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import {
	IoAlert,
	IoDocumentTextOutline,
	IoFolderOpenOutline,
	IoFolderOutline
} from 'react-icons/io5';
import { FileRecord, ValidityIssue, FileIssue } from '../../../types';
import FileTooltip from './FileTooltip';

const useStyles = makeStyles<Theme, { issues?: Pick<FileIssue, "severity">[] }>(
	(theme: Theme) => ({
		action: { alignSelf: 'center', flex: 1 },

		list: {
			paddingLeft: theme.spacing(2),
		},
		file: {
			display: 'inline',
		},
		folder: {
			padding: 0,
			height: 20,
		},
		fileDescription: ({ issues }) => {
			const commonProps = {};
			if (issues) {
				if (issues.some((i) => i.severity === 'error')) {
					return {
						...commonProps,
						color: theme.palette.error.main,
					};
				} else {
					return { ...commonProps, color: theme.palette.warning.dark };
				}
			}
			return commonProps;
		},

		icon: {
			fontSize: 15,
			minWidth: 0,
			color: 'inherit',
		},
		text: {
			fontSize: 12,
			paddingLeft: 0,
			whiteSpace: 'nowrap',
			width: 'auto',
		},
	})
);

type FileDirectory = { [directory: string]: FileDirectory };

interface FileTreeProps {
	filename: string;
	directory: FileDirectory | FileRecord;
	folderIssues: { [directory: string]: ValidityIssue[] };
}

export function FileTree(props: FileTreeProps) {
	const { filename, directory, folderIssues } = props;
	const [collapsed, setCollapsed] = React.useState(false);

	const fileIssues: FileIssue[] | undefined = useMemo(() => {
		if (directory.filename) {
			return (directory as FileRecord).issues;
		}
	}, [directory]);

	const directoryIssues: ValidityIssue[] | undefined = useMemo(() => {
		return folderIssues['processed/'.concat(filename)];
	}, [filename, folderIssues]);

	const classes = useStyles({ issues: fileIssues || directoryIssues});

	const handleCollapse = () => {
		setCollapsed(!collapsed);
	};

	const renderFilename = filename.slice(filename.lastIndexOf('/') + 1);
	if (renderFilename === "_blank (0B)") return null;	// HACK: _blank file inserted to render parent folder

	if (directory.filename) {
		// To display the tooltip we have to wrap it in another component
		const fileRepresentation =
			fileIssues && fileIssues.length > 0 ? (
				<FileTooltip filename={filename} issues={fileIssues} isDirectory={false}>
					<Typography
						className={[classes.text, classes.fileDescription].join(' ')}
					>
						{renderFilename}
					</Typography>
				</FileTooltip>
			) : (
				<Typography className={classes.text}>{renderFilename}</Typography>
			);

		// Render the file representation

		return (
			<List component="div" className={classes.list} disablePadding dense>
				<ListItem className={classes.folder}>
					<ListItemIcon
						classes={{
							root: classes.icon,
						}}
					>
						<IoDocumentTextOutline />
						{fileIssues && fileIssues.length > 0 && (
							<IoAlert className={classes.fileDescription} />
						)}
					</ListItemIcon>
					{fileRepresentation}
				</ListItem>
			</List>
		);
	}

	// Render the directory
	return (
		<List
			key={filename}
			component="div"
			className={classes.list}
			disablePadding
			dense
		>
			<ListItem button onClick={handleCollapse} className={classes.folder}>
				<ListItemIcon className={classes.icon}>
					{!collapsed ? <IoFolderOpenOutline /> : <IoFolderOutline />}
					{directoryIssues && directoryIssues.length > 0 && (
						<IoAlert className={classes.fileDescription} />
					)}
				</ListItemIcon>
				{directoryIssues && directoryIssues.length > 0 ? (
					<FileTooltip filename={filename} issues={directoryIssues} isDirectory>
						<Typography
							className={[classes.text, classes.fileDescription].join(' ')}
						>
							{renderFilename}
						</Typography>
					</FileTooltip>
				) : (
					<Typography className={classes.text}>{renderFilename}</Typography>
				)}
			</ListItem>
			<Collapse in={!collapsed} timeout="auto" unmountOnExit>
				{Object.entries(directory).map(([childFilename, childDir]) => (
					<FileTree
						key={childFilename}
						filename={`${filename}/${childFilename}`}
						directory={childDir}
						folderIssues={folderIssues}
					></FileTree>
				))}
			</Collapse>
		</List>
	);
}

export default FileTree;
