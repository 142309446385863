import {
	Button,
	Container,
	makeStyles,
	TextField,
	Theme,
	Typography
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { authenticate } from '../../api';
import { useSignInRedirects } from '../../hooks/useSignInRedirect';
import { AuthContext, OauthProvider } from '../../types';
import AppleSignInButton from '../AppleSignInButton';
import { GoogleSignInButton } from '../GoogleSignInButton';

const useStyles = makeStyles((theme: Theme) => ({
	loginBox: {
		marginTop: theme.spacing(10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	oauthButton: {
		marginTop: theme.spacing(2),
		cursor: 'pointer',
		width: 210,
		height: 40,
	},
	error: {
		marginTop: theme.spacing(1),
	},
	form: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: theme.spacing(2),
	},
	accessCodeWrapper: {
		// borderRadius: theme.shape.borderRadius,
		marginRight: theme.spacing(1),
	},
	accessCode: {
		textAlign: 'center',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	submitButton: {
		marginLeft: theme.spacing(1),
	},
}));

interface LoginScreenProps {}

export default function LoginScreen(props: LoginScreenProps) {
	const classes = useStyles();

	const [jwtPair, setJWTPair] = useState<{
		token: string;
		provider: OauthProvider;
	}>();
	const [visibleError, setVisibleError] = useState<string>();
	const [needsAccessCode, setNeedsAccessCode] = useState(false);
	const [accessCode, setAccessCode] = useState<string>('');
	const [submittedAccessCode, setSubmittedAccessCode] = useState(false);

	const { onSignIn } = useContext(AuthContext);

	const signInDirector = useSignInRedirects();

	useEffect(
		function exchangeToken() {
			if (!jwtPair || (needsAccessCode && !submittedAccessCode)) return;

			const [pAuth, abortController] = authenticate({
				token: jwtPair.token,
				provider: jwtPair.provider,
				access_token: submittedAccessCode ? accessCode.trim() : undefined,
			});
			pAuth.then(([success, error]) => {
				if (!error) {
					onSignIn(success!);
					signInDirector();

					// No need to do further on this screen, expect disassembly soon
				} else {
					switch (error.status) {
						case 400:
							setVisibleError('App authentication error. Contact support');
							break;
						case 403:
							setVisibleError('Invalid access code. Please try again');
							break;
						case 404:
							setNeedsAccessCode(true);
							break;
					}
					setSubmittedAccessCode(false);
				}
			});

			return () => {
				abortController.abort();
			};
		},
		[
			jwtPair,
			submittedAccessCode,
			accessCode,
			needsAccessCode,
			onSignIn,
			signInDirector,
		]
	);

	const validCode = useMemo(() => {
		return /^\w{6}$/.test(accessCode.trim());
	}, [accessCode]);

	const submitDisabled = !validCode || submittedAccessCode;

	return (
		<Container maxWidth="sm">
			<div className={classes.loginBox}>
				<Typography variant="h1">Sign in to SDM</Typography>
				<Typography variant="subtitle1">Study Data Manager</Typography>

				{!needsAccessCode ? (
					<>
						<div className={classes.oauthButton}>
							<AppleSignInButton
								onSuccess={(d) => {
									const token = d.detail.authorization.id_token;
									setJWTPair({ token, provider: 'appleid' });
								}}
								onFailure={(f) => {
									console.error('Failed to sign in to Apple!', f);
								}}
							/>
						</div>
						<div className={classes.oauthButton}>
							<GoogleSignInButton
								width={210}
								onSuccess={(d) => {
									const token = d.credential;
									setJWTPair({ token, provider: 'google' });
								}}
								onFailure={(f) => {
									console.error('Failed to sign in to Google!', f);
								}}
							/>
						</div>
					</>
				) : (
					<div className={classes.form}>
						<TextField
							variant="outlined"
							label="Access Code"
							size="small"
							helperText="Enter your 6-digit access code"
							error={accessCode.length !== 0 && !validCode}
							value={accessCode}
							disabled={submittedAccessCode}
							onKeyPress={(event) => {
								if (event.key === 'Enter' && !submitDisabled) {
									event.preventDefault();
									setSubmittedAccessCode(true);
								}
							}}
							inputProps={{ className: classes.accessCode }}
							onChange={(e) => setAccessCode(e.target.value)}
						/>
						<Button
							disabled={submitDisabled}
							variant="contained"
							className={classes.submitButton}
							color="primary"
							onClick={() => {
								setSubmittedAccessCode(true);
							}}
						>
							Submit
						</Button>
					</div>
				)}

				{visibleError && (
					<Typography color="error" className={classes.error}>
						{visibleError}
					</Typography>
				)}
			</div>
		</Container>
	);
}
