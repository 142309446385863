import {
	AppBar,
	Button,
	Toolbar,
	Typography,
	useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';
import useAuth from '../hooks/useAuth';
import { decodeJws } from '../utilities/jwt';
import type { AppTheme } from './theme';

const useStyles = makeStyles<AppTheme>((theme) => ({
	bar: {
		backgroundColor: 'transparent',
	},
	grow: {
		flexGrow: 1,
	},
	profileButton: {
		padding: theme.spacing(1),
	},
	name: {
		marginRight: theme.spacing(1),
		color: theme.palette.text.hint,
	},
	toolbar: theme.mixins.toolbar,
}));

interface HeaderProps {}

export default function Header(props: HeaderProps) {
	const styles = useStyles();

	const theme = useTheme();
	const { access_token, onSignOut } = useAuth();

	const name = useMemo(() => {
		if (!access_token) return null;

		return decodeJws<{ Name: string }>(access_token).payload.Name;
	}, [access_token]);

	return (
		<>
			<AppBar
				style={{
					borderBottom: `1px solid ${theme.palette.divider}`,
					backgroundColor: 'white',
				}}
			>
				<Toolbar>
					<Link to="/">
						<img src={logo} height={40} alt="logo" />
					</Link>

					{access_token && (
						<>
							<div className={styles.grow} />
							<Typography className={styles.name}>{name}</Typography>
							<Button variant="text" color="primary" onClick={onSignOut}>
								Sign Out
							</Button>
						</>
					)}
				</Toolbar>
				<div className={styles.separator} />
			</AppBar>
			<div className={styles.toolbar} />
		</>
	);
}
