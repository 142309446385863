import * as Google from 'google';
import React, { useEffect, useRef } from 'react';

type GoogleSignInButtonProps = {
	onSuccess(data: Google.accounts.id.CredentialResponse): void;
	onFailure?(e: any): void;
} & Partial<Google.accounts.id.RenderConfig>;

export function GoogleSignInButton({
	onSuccess,
	onFailure,
	...renderConfig
}: GoogleSignInButtonProps) {
	const buttonRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		Google.accounts.id.initialize({
			client_id:
				'602058204417-m3vp7omirf5uii6mobkm4u24hruo15kr.apps.googleusercontent.com',
			callback: onSuccess,
		});
		Google.accounts.id.renderButton(buttonRef.current!, {
			theme: 'outline',
			size: 'large',
			...renderConfig,
		});
	}, [onSuccess, renderConfig]);
	return <div id="google-sign-in-btn" ref={buttonRef}></div>;
}

export default GoogleSignInButton;
