import { Typography } from '@material-ui/core';
import { DeviceRenderStrategy } from '../../../types';

export const LoadingRenderStrategy: DeviceRenderStrategy = {
	title: 'Loading...',
	showCoverage: false,
	renderHeader: () => (
		<div>
			<Typography>Retrieving device and file records, please wait</Typography>
		</div>
	),
};

export default LoadingRenderStrategy;
