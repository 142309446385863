import { Box, makeStyles, Theme } from '@material-ui/core';
import { useContext } from 'react';
import { RouteProps } from 'react-router-dom';
import { useBailout } from '../../hooks/useBailout';
import { StudyContext } from '../../types';
import ParticipantList from '../ParticipantList';
import ParticipantDetails from './ParticipantDetails';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'hidden',
	},
}));

interface ParticipantScreenProps{
	onSyncStudy(): void;
	syncing: boolean;
	onRemovedParticipant: (participantId: string) => void;
}


export default function ParticipantScreen(props: RouteProps & ParticipantScreenProps) {
	const classes = useStyles();

	const studyData = useContext(StudyContext);
	// const [error, setError] = useState<FetchError | undefined>();

	const bailout = useBailout();
	if (bailout) return bailout;

	return (
		<Box className={classes.container}>
				<ParticipantList
					participants={studyData?.participants && Object.values(studyData?.participants)}
					onDoUpdate={props.onSyncStudy}
					syncing={props.syncing}
					// error={error}
					recruitmentFormUrl={studyData?.recruitmentFormUrl}
				/>
				<ParticipantDetails onRemovedParticipant={props.onRemovedParticipant} />
		</Box>
	);
}