import {
	DeviceRenderStrategy, FetchError
} from '../../../types';
import MessagePage from '../../MessagePage';
import BiotelDeviceRenderStrategy from './BiotelRenderStrategy';
import DexcomG6DeviceRenderStrategy from './DexcomG6RenderStrategy';
import DexcomLactateDeviceRenderStrategy from './DexcomLactateRenderStrategy';
import EmpaticaDeviceRenderStrategy from './EmpaticaRenderStrategy';
import ErrorDeviceRenderStrategy from './ErrorRenderStrategy';
import FinapresNovaDeviceRenderStrategy from './FinapresNovaRenderStrategy';
import OuraDeviceRenderStrategy from './OuraRenderStrategy';
import PhilipsDeviceRenderStrategy from './PhilipsRenderStrategy';
import QuestDeviceRenderStrategy from './QuestRenderStrategy';
import ShimmerDeviceRenderStrategy from './ShimmerRenderStrategy';
import { UnsupportedDeviceRenderStrategy } from './UnsupportedDeviceRenderStrategy';
import ZephyrDeviceRenderStrategy from './ZephyrRenderStrategy';

export { LoadingRenderStrategy } from './LoadingRenderStrategy';

type DeviceHandler = {
	render: DeviceRenderStrategy;
	extensions?: string[];
	configVarOverrides?: {
		observation_fields?: string;
	};
};

const deviceStrategies: {
	[device_type: string]: DeviceHandler;
} = {
	BioTel_ePatch: {
		render: BiotelDeviceRenderStrategy,
		extensions: ['.efs', '.ecg', '.info', '.ecg1', '.txt'],
	},
	Dexcom_CGM: {
		render: DexcomG6DeviceRenderStrategy,
	},
	Dexcom_Lactate: {
		render: DexcomLactateDeviceRenderStrategy,
	},
	Empatica_E4: {
		render: EmpaticaDeviceRenderStrategy,
	},
	Finapres_Nova: {
		render: FinapresNovaDeviceRenderStrategy,
	},
	Oura_Ring: {
		render: OuraDeviceRenderStrategy,
	},
	Quest_Bloodwork: {
		render: QuestDeviceRenderStrategy,
		extensions: ['.txt', '.csv', '.xlsx', '.xls'],
	},
	Philips_Monitor: {
		render: PhilipsDeviceRenderStrategy,
	},
	Shimmer_EMG: {
		render: ShimmerDeviceRenderStrategy,
		extensions: ['.txt', '.csv'],
	},
	Zephyr_Strap: {
		render: ZephyrDeviceRenderStrategy,
		extensions: ['.csv', '.hed', '.dat', '.txt'],
	},
};

export function getRenderStrategy(deviceType: string, error?: FetchError): DeviceRenderStrategy {
	let strategy = deviceStrategies[deviceType]?.render;
	if (!strategy) return UnsupportedDeviceRenderStrategy;

	if (!error) {
		return strategy;
	}

	return chooseErrorStrategy(error);
}

export function getAcceptedExtensions(
	deviceType: string | null
): string[] | undefined {
	if (!deviceType) return undefined;

	return deviceStrategies[deviceType]?.extensions;
}

function chooseErrorStrategy(error: FetchError) {
	let content: JSX.Element;

	switch (true) {
		case error?.status === -1:
			content = (
				<MessagePage
					title="Network Unavailable"
					message="Please check your network connection and try again."
				/>
			);
			break;
		case error?.status === 500:
			// TODO: Provide an actual support method.
			content = (
				<MessagePage
					title="Server Problem"
					message="Please wait a moment and try again. If the problem persists please contact support."
				/>
			);
			break;
		case error?.status === 403:
			content = (
				<MessagePage
					title="Not Authorized"
					message="You do not have access to this study. Return to the study selection page and start again."
				/>
			);
			break;
		default:
			content = (
				<MessagePage
					title="Unexpected error"
					message={`There was a problem loading the device data. Please refresh and try again. If the problem persists please contact support. Error code ${
						error!.status
					}.`}
				/>
			);
			break;
	}

	return ErrorDeviceRenderStrategy(content);
}
