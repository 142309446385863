import { Grid } from '@material-ui/core';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import {
	redcapSensorAppliedTime,
	redcapSensorRemovalTime,
	renderFields,
} from './utils';

const VAR_NAME_SENSOR_APPLIED = 'var_finapres_applied';
const VAR_NAME_SERIAL_NUMBERS = 'vars_finapres_sn';
const VAR_NAME_SENSOR_REMOVAL = 'var_finapres_removal';

export const FinapresNovaDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Finapres Nova',
	sensorApplicationVar: VAR_NAME_SENSOR_APPLIED,
	sensorRemovalVar: VAR_NAME_SENSOR_REMOVAL,
	showCoverage: true,
	renderHeader: ({ redcapFields, deviceConfig, studyConfig }) => {
		const snFieldNames = deviceConfig[VAR_NAME_SERIAL_NUMBERS] as
			| string[]
			| undefined;
		const snRendered = renderFields(
			snFieldNames,
			redcapFields,
			'serial number'
		);
		const appliedRendered = redcapSensorAppliedTime(
			redcapFields,
			VAR_NAME_SENSOR_APPLIED,
			studyConfig,
			deviceConfig
		);

		const removalRendered = redcapSensorRemovalTime(
			redcapFields,
			VAR_NAME_SENSOR_REMOVAL,
			studyConfig,
			deviceConfig
		);
		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Serial Number" value={snRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Removed" value={removalRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Assigned" value={appliedRendered} />
				</Grid>
			</Grid>
		);
	},
};

export default FinapresNovaDeviceRenderStrategy;
