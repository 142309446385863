import Dropzone, { DropzoneOptions } from 'dropzone';
import { MutableRefObject, useEffect, useRef } from 'react';
import './dropzoneSDM.scss';

type DropzoneReactProps = {
	dropzoneRef: MutableRefObject<Dropzone | undefined>;
	extensions?: string[];
	dropzoneOptions: DropzoneOptions;
	onReady(): void;
};

let acceptMode = acceptAllFiles;

let handleAccept = (file: File, done: (error?: string) => void) => {
	return acceptMode(file, done);
};
export default function DropzoneReact(props: DropzoneReactProps) {
	const dropRef = useRef<HTMLDivElement>(null);

	const { dropzoneOptions: dropOpts, onReady, dropzoneRef, extensions } = props;
	// fixme: memoize this if possible using deep equality comparison
	// const options = useMemo(() => {

	if (props.dropzoneRef?.current) {
		// Update props if already rendered
		Object.assign(props.dropzoneRef.current.options, dropOpts);
	}
	// 	return opts;
	// }, [dropOpts]);

	useEffect(() => {
		// Make sure Dropzone doesn't try to attach itself to the
		// element automatically.
		// This behaviour will change in future versions.
		Dropzone.autoDiscover = false;

		const mergedExtensions = Array.from(
			new Set(
				dropOpts.acceptedFiles?.split(',').concat(extensions ?? []) ??
					extensions
			).values()
		);
		dropOpts.acceptedFiles = mergedExtensions.length
			? mergedExtensions.join(',')
			: undefined;

		if (!dropOpts.accept) {
			dropOpts.accept = handleAccept;
		}

		const dropzone = new Dropzone('#drop-zone', dropOpts);
		if (dropzoneRef) {
			dropzoneRef.current = dropzone;
		}

		dropzone.on('successmultiple', () => {
			dropzone.processQueue();
		});

		onReady();

		return () => {
			dropzone.off();
			dropzone.destroy();
		};
	}, [dropOpts, dropzoneRef, extensions, onReady]);

	useEffect(
		function resetAcceptFilter() {
			acceptMode = blockUnsupportedFiles(extensions);
		},
		[extensions]
	);

	return <div ref={dropRef} id="drop-zone" className="dropzone" />;
}

function acceptAllFiles(f: File, done: (error?: string) => void) {
	done();
}

function blockUnsupportedFiles(extensions?: string[]) {
	if (!extensions) return acceptAllFiles;
	return function (f: File, done: (result?: string) => void) {
		const flnLower = f.name.toLocaleLowerCase();
		const isSupported =
			extensions?.findIndex((e) => flnLower.endsWith(e.toLocaleLowerCase())) >=
			0;
		if (isSupported) {
			done();
		} else {
			done('Unsupported file type');
		}
	};
}
