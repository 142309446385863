import { Grid, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import React from 'react';
import { FetchError, StudyListing } from '../types';
import MessagePage from './MessagePage';
import StudyListItem from './StudyListItem';

interface StudyListProps {
	error?: FetchError;
	studies?: StudyListing[];
	onReload: () => void;
}

export default function StudyList({ studies, error, onReload }: StudyListProps) {
	const hasStudies = (studies ?? []).length > 0;

	const theme = useTheme();

	let content: JSX.Element;

	switch (true) {
		case error?.status === -1:
			content = (
				<MessagePage
					title="Network Unavailable"
					message="Please check your network connection and try again."
				/>
			);
			break;
		case error?.status === 500:
			// TODO: Provide an actual support method.
			content = (
				<MessagePage
					title="Server Problem"
					message="Please wait a moment and try again. If the problem persists please contact support."
				/>
			);
			break;
		case error?.status === 403:
			content = (
				<MessagePage
					title="Not Authorized"
					message="You do not have access to studies. Return to the study selection page and start again."
				/>
			);
			break;
		case error && error.status >= 400:
			content = (
				<MessagePage
					title="Unexpected error"
					message={`There was a problem loading your studies. Please refresh and try again. If the problem persists please contact support. Error code ${
						error!.status
					}.`}
					action={
						<Button variant="outlined" color="primary" onClick={onReload}>
							Reload
						</Button>
					}
				/>
			);
			break;
		case studies === undefined:
			content = (
				<MessagePage
					title="Loading..."
					message="Retrieving your studies. Please wait..."
				/>
			);
			break;
		case !hasStudies:
			content = (
				<MessagePage
					title="No Studies"
					message="Your account is not linked to any studies."
					action={
						<Button variant="outlined" color="primary" onClick={onReload}>
							Reload
						</Button>
					}
				/>
			);
			break;
		default:
			content = (
				<Grid container spacing={2} style={{}}>
					{studies!
						.sort((a, b) => {
							const diff = (b.completed ? 0 : 1) - (a.completed ? 0 : 1);
							if (diff !== 0) return diff;
							return a.name.localeCompare(b.name);
						})
						.map((study, i) => (
							<Grid item key={study.name}>
								<StudyListItem key={study.id} {...study} />
							</Grid>
						))}
				</Grid>
			);
			break;
	}

	return (
		<Container maxWidth="lg" style={{ marginTop: theme.spacing(3) }}>
			{content}
		</Container>
	);
}
