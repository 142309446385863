import {
	Button,
	Card,
	CardActions,
	CardHeader,
	Typography,
	useTheme
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { lighten, makeStyles, Theme } from '@material-ui/core/styles';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { StudyListing } from '../types';

const width = 280,
	height = 120,
	margin = 10,
	radius = Math.min(width, height) / 2 - margin;

const useStyles = makeStyles<Theme, { completed: boolean }>(() => ({
	container: ({ completed }) => ({
		width: 300,
		// margin: '1em',
		backgroundColor: completed ? grey[100] : undefined,
	}),
	title: ({ completed }) => ({
		// fontSize: 28,
		color: completed ? grey[600] : undefined,
		textAlign: 'left',
	}),
	chartWrapper: {
		alignSelf: 'center',
		position: 'relative',
	},
	participants: ({ completed }) => ({
		color: completed ? grey[600] : grey[800],
	}),
	placeholder: ({ completed }) => ({
		alignSelf: 'center',
		color: completed ? grey[600] : grey[800],
	}),
	placeholderWrapper: { justifyContent: 'center', display: 'flex', height },
}));

export type StudyListItemProps = StudyListing;

export default function StudyListItem({
	id,
	name,
	participants,
	completion,
	completed,
}: StudyListItemProps) {
	const chartRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();

	const incompleteColor = lighten(theme.palette.primary.light, 0.5);
	const completeColor = theme.palette.primary.main;
	useEffect(
		function drawChart() {
			const svg = d3
				.select(chartRef.current!)
				.append('svg')
				.attr('width', width)
				.attr('height', height);

			const pieChart = svg
				.append('g')
				.attr('transform', `translate(${width / 2.6},${height / 2 + 10})`);

			const data = [completion, 100 - completion];

			function color(i: number) {
				return i === 0 ? completeColor : incompleteColor;
			}

			// Chart
			const data_ready = d3.pie().sort(null)(data);

			const u = pieChart.selectAll('path').data(data_ready);

			u.enter()
				.append('path')
				// @ts-ignore types are fine
				.merge(u)
				.transition()
				.duration(250)
				.attr(
					'd',
					// @ts-ignore come on, there's nothing wrong here!
					d3
						.arc()
						.innerRadius(() => (completed ? 0 : radius * 0.618))
						.outerRadius(radius)
				)
				.attr('fill', function (d, i) {
					return color(i);
				})
				.style('opacity', 1);

			u.exit().remove();

			// Legend

			const legend = svg.append('g').attr('transform', `translate(${180}, 10)`);
			// Add one dot in the legend for each name.
			legend
				.selectAll('mydots')
				.data(data)
				.enter()
				.append('circle')
				.attr('cx', 0)
				.attr('cy', function (d, i) {
					return 7 + i * 25;
				}) // 100 is where the first dot appears. 25 is the distance between dots
				.attr('r', 7)
				.style('fill', function (d, i) {
					return color(i);
				});

			// Add one dot in the legend for each name.
			legend
				.selectAll('mylabels')
				.data(data)
				.enter()
				.append('text')
				.attr('x', 10)
				.attr('y', function (d, i) {
					return 9 + i * 25;
				}) // 100 is where the first dot appears. 25 is the distance between dots
				.style('fill', function (d, i) {
					return color(i);
				})
				.text(function (_, i) {
					return i === 0 ? 'Complete' : 'Incomplete';
				})
				.attr('text-anchor', 'left')
				.style('alignment-baseline', 'middle');
		},
		[participants, completion, completed, completeColor, incompleteColor]
	);

	const styles = useStyles({ completed });
	return (
		<Button component={Link} to={`/studies/${id}`}>
			<Card className={styles.container}>
				<CardHeader
					className={styles.title}
					classes={{
						title: styles.title,
					}}
					title={`${name} ${completed ? '(completed)' : ''}`}
				></CardHeader>
				{/* <CardContent> */}
				{participants ? (
					<div ref={chartRef} className={styles.chartWrapper} />
				) : (
					<div className={styles.placeholderWrapper}>
						<Typography className={styles.placeholder}>
							No participants recruited yet
						</Typography>
					</div>
				)}
				{/* </CardContent> */}
				<CardActions>
					<Typography
						className={styles.participants}
					>{`${participants} participants`}</Typography>
				</CardActions>
			</Card>
		</Button>
	);
}
