import React, { createContext } from 'react';

export interface StudyListing {
	/**
	 * Study's unique identifier
	 */
	id: string;

	/**
	 * Study human-readable name
	 */
	name: string;

	/**
	 * Number of participants enrolled in the study
	 */
	participants: number;

	/**
	 * Percent completion as an integer number
	 */
	completion: number;

	/**
	 * Whether the study has been completed
	 */
	completed: boolean;
}

export interface ParticipantsFetchResponse {
	participants_data: ParticipantListing[];
	next: string;
	prev: string;
}

export interface ParticipantListing {
	id: string;
	last_upload?: string;
	completion: number;
	issue_severity: number;
	finalized: string | null;
	completed: string | null;
	application_time: string;
	removal_time: string;
	devices: DeviceMeta[];
}

interface StudyPermissions {
	can_finalize?: boolean;
	can_remove_participants?: boolean;
	can_delete_files?: boolean;
}

interface LabworkDetails {
	panel: "Cytokines" | "Hormones";
	last_upload: string;
}

export interface StudyDetails {
	id: string;
	pid: number;
	prefix: string;
	name: string;
	timezone: string;
	completed: boolean;
	config: {
		observation_fields: Array<string>;
		// Whether the study is a 'classic' REDCap project with repeating instruments
		is_classic: boolean;
	} & ConfigValue;
	numActiveParticipants: number; 
	numFinalizedParticipants: number;
	numRemovedParticipants: number;
	devices: DeviceServerConfig[];
	events: RedcapEvent[];
	recruitmentFormUrl: string;
	userPermissions: StudyPermissions;
	labwork?: LabworkDetails[];
}

export interface RedcapEvent {
	event_id: string;
	page: string;
	name: string;
	event_order: number;
}

export interface RedCapForm {
	url: string;
	name: string;
	last_submit: string;
	status: 0 | 1 | 2;
	warning?: string;
}

export interface DeviceServerConfig {
	device_type: string;
	name: string;
	instructions: string;
	cloud_sync: boolean;
	config?: DeviceMetaConfig; // Shape can vary by device, though it should be objects
}

type DeviceMetaConfig = {
	// REDCap variable which should drive whether or not the device is active for the participant
	redcapDeviceUsedField?: string;
} & ConfigValue;
export interface DeviceMeta {
	device_type: string;
	last_submit?: string;
	completion: number;
	issue_severity: number;
	logged_days: { [date: string]: boolean };
}

export interface RedcapData {
	[fieldId: string]: string;
}

export type ParticipantDetails = {
	id: string;
	// forms: RedCapForm[],	// FIXME: Uncomment when forms are ready
	devices: DeviceMeta[];
	completed: string | null;
	finalized: string | null;
	redcap_events?: RedcapData[];
	redcap_fields?: RedcapData;
};

export type StudyParticipants = Record<string, ParticipantListing> | undefined;

export const StudyContext =
	React.createContext<(StudyDetails & {participants: StudyParticipants}) | undefined>(undefined);

export interface AuthenticateConfig {
	token: string;
	provider: OauthProvider;
	access_token?: string;
}

export interface AuthenticateSuccess {
	refresh_token: string;
	access_token: string;
}

export type OauthProvider = 'appleid' | 'google';

export type FileRecord = {
	filename: string;
	size: number;
	modified: string;
	issues: FileIssue[];
	status: 'pending' | 'verified' | 'processed' | 'rejected' | 'deleted';
	delivered: string | null;
};

export type ConfigValue = {
	[key: string]: string | string[];
};

type DeviceHeaderRenderProps = {
	onIngest: (deviceType: string, payload?: File) => void;
	status: DeviceStatus;
	last_sync?: string;
	studyConfig: ConfigValue;
	deviceConfig: ConfigValue;
	isClassic: boolean;
	redcapFields: RedcapData | RedcapData[]; // Array when isClassic == true
};

export type DeviceRenderStrategy = {
	title: string;
	sensorApplicationVar?: string;
	sensorRemovalVar?: string;
	showCoverage: boolean;
	renderHeader: (
		headerProps: DeviceHeaderRenderProps & { time_zone: string }
	) => JSX.Element;
};

export type NetworkDeviceDetails = {
	device_type: string;
	completion: number;
	files: FileRecord[];
	issues: ValidityIssue[];
};

export type DeviceStatus = 'idle' | 'fetching' | 'ingesting';

export type FetchError = {
	status: number;
	error: string;
};

export type FetchResult<T> = Promise<[T, null] | [null, FetchError]>;

type AuthContextType = Partial<AuthenticateSuccess> & {
	onSignIn: (creds: AuthenticateSuccess) => void;
	onSignOut: () => void;
};

export const AuthContext = createContext<AuthContextType>({
	onSignIn: () => { },
	onSignOut: () => { },
});

export interface DeviceInfo {
	data?: NetworkDeviceDetails;
	status: 'idle' | 'ingesting' | 'fetching';
	issue_severity?: number;
	progress?: number;
	error?: FetchError;
	last_submit?: string;
}

export type DeviceRegistry = {
	[deviceId: string]: DeviceInfo;
};

export type ValidityIssue = {
	location: string;
	message: string;
	severity: 'warning' | 'error' | 'critical';
};

export type FileIssue = {
	code: string;
	message: string;
	severity: 'warning' | 'error' | 'critical';
}
