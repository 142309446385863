import {
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	Typography,
	useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime, Interval } from 'luxon';
import { useContext, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
	IoCheckmark as CompletedIcon,
	IoCheckmarkDone as FinalizedIcon
} from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { ParticipantListing, StudyContext } from '../types';
import CoverageGraph, { days } from './deviceDetails/CoverageGraph';

Chart.register(...registerables);

const useStyles = makeStyles(() => ({
	container: {
		flex: 1,
	},
	link: {
		flex: 1,
		minWidth: 330,
	},
	cardHeader: {
		padding: 8,
	},
	timeline: {
		height: '45px',
		width: '100%',
		marginTop: '-20px',
	},
	labels: {
		marginLeft: '4px',
		height: '35px',
		width: '100%',
		marginTop: '-25px',
	},
}));

export default function ParticipantCard({
	id,
	finalized,
	completed,
	devices,
	application_time,
	removal_time,
}: ParticipantListing) {
	const { devices: devicesConfigs } = useContext(StudyContext)!;
	const statusMessage: string =
		useMemo(() => {
			let significantDate: DateTime;
			if (finalized) {
				significantDate = DateTime.fromISO(finalized);
				return `Finalized ${significantDate.toLocaleString(
					DateTime.DATE_SHORT
				)}`;
			} else if (completed) {
				significantDate = DateTime.fromISO(completed);
				return `Sent ${significantDate.toLocaleString(DateTime.DATE_SHORT)}`;
			} else {
				return 'Not yet sent';
			}
		}, [completed, finalized]);

	const theme = useTheme();
	const styles = useStyles();

	const { studyId } = useParams<{ studyId: string }>();

	const statusIcon =
		finalized || completed ? (
			finalized ? (
				<FinalizedIcon color={theme.palette.primary.main} />
			) : (
				<CompletedIcon color={theme.palette.primary.main} />
			)
		) : null;

	const endTime = DateTime.local()
	const startTime = endTime.minus({ days: 13 })

	const intervalLabels = Array.from(days(Interval.fromDateTimes(startTime, endTime)))
		.map((date, i) => {
			return date.toLocaleString({
				month: 'short',
				day: 'numeric',
			});
		});
	const axisChartOptions: ChartOptions<'line'> = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					// @ts-ignore (fine if it's undefined)
					label: () => undefined,
				},
			},
		},
		maintainAspectRatio: false,
		scales: {
			x: {
				grid: {
					display: true,
					drawOnChartArea: false,
					borderColor: 'darkgrey',
					tickColor: 'darkgrey',
				},
				ticks: {
					align: 'inner',
					callback: (value, index, ticks) => {
						if (index === 0 || index === (ticks.length - 1)) {
							return intervalLabels[index];
						}
						return null;
					},
					color: 'grey',
				},
			},
			y: {
				display: false,
			},
		},
	};
	const axisChartData: ChartData<'line'> = {
		labels: intervalLabels,
		datasets: [
			{
				data: [],
			},
		],
	};

	const interval = Array.from(days(Interval.fromDateTimes(startTime, endTime.plus({ days: 1 }))));
	const removalTimeISO = DateTime.fromISO(removal_time).toISODate();
	const appliedTimeISO = DateTime.fromISO(application_time).toISODate();

	const labelChartOptions: ChartOptions<'line'> = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					// @ts-ignore (fine if it's undefined)
					label: () => undefined,
				},
			},
		},
		maintainAspectRatio: false,
		scales: {
			x: {
				grid: {
					drawBorder: false,
					drawTicks: false,
					display: true,
					drawOnChartArea: false,
					borderColor: 'darkgrey',
					tickColor: 'darkgrey',
				},
				ticks: {
					align: 'center',
					callback: (value, index, ticks) => {
						const tickDate = interval[index].toISODate();
						if (tickDate === removalTimeISO) {
							return 'Done';
						}
						if (tickDate === appliedTimeISO) {
							return 'Start';
						}

						return null;
					},
					color: 'grey',
				},
			},
			y: {
				display: false,
			},
		},
	};
	const labelChartData: ChartData<'line'> = {
		labels: interval,
		datasets: [
			{
				data: [],
			},
		],
	};

	return (
		<Button component={Link} to={`/studies/${studyId}/${id}`} className={styles.link}>
			<Card className={styles.container}>
				<Grid container className={styles.cardHeader}>
					<Grid item xs={1} container justifyContent="center" alignItems="center">{statusIcon}</Grid>
					<Grid item xs={5} container direction="column">
						<Typography variant="h3">{id}</Typography>
						<Typography variant="body2" color="textSecondary">{statusMessage}</Typography>
					</Grid>
					<Grid item xs={6} container alignItems="flex-end">
						<Typography variant="body1" color="textSecondary">{'Last 2 weeks'}</Typography>
					</Grid>
				</Grid>
				<Divider variant="fullWidth" />
				<CardContent>
					{/* overall chart is rendered row by row instead of a single chart */}
					<Grid container key={"labels"}>
						<Grid item xs={6}></Grid>
						<Grid item xs={6} container alignItems="center" justifyContent="center">
							<div className={styles.labels}>
								<Line options={labelChartOptions} data={labelChartData} />
							</div>
						</Grid>
					</Grid>
					{devices?.slice().sort((a, b) => {
						return a.device_type.localeCompare(b.device_type);
					})
						.map((device, i) => {
							return (
								<Grid container key={device.device_type}>
									<Grid item xs={6} container alignItems="center">
										<Typography variant="subtitle2">{devicesConfigs?.find((element) => {
											return device.device_type === element.device_type
										})?.name ?? 'Unknown Device Name'}</Typography>
									</Grid>
									<Grid item xs={6} container alignItems="center" justifyContent="center">
										<CoverageGraph
											appliedTime={DateTime.fromISO(application_time)}
											removalTime={DateTime.fromISO(removal_time)}
											loggedDays={device.logged_days}
											condenseGraph={true}
										/>
									</Grid>
								</Grid>
							);
						})}
					<Grid container key={"axis"}>
						<Grid item xs={6}></Grid>
						<Grid item xs={6} container alignItems="center" justifyContent="center">
							<div className={styles.timeline}>
								<Line options={axisChartOptions} data={axisChartData} />
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Button>
	);
}