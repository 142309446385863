import { Grid } from '@material-ui/core';
import { DeviceRenderStrategy } from '../../../types';
import DeviceDetailItem from '../DeviceDetailItem';
import {
	redcapSensorAppliedTime,
	redcapSensorRemovalTime,
	renderFields,
} from './utils';

const VAR_NAME_SENSOR_APPLIED = 'var_zephyr_applied';
const VAR_NAME_SERIAL_NUMBER = 'vars_zephyr_sn';
const VAR_NAME_SENSOR_REMOVAL = 'var_zephyr_removal';

export const ZephyrDeviceRenderStrategy: DeviceRenderStrategy = {
	title: 'Zephyr Strap',
	sensorApplicationVar: VAR_NAME_SENSOR_APPLIED,
	sensorRemovalVar: VAR_NAME_SENSOR_REMOVAL,
	showCoverage: true,
	renderHeader: ({
		redcapFields,
		studyConfig,
		deviceConfig,
	}) => {
		const zephyrFieldNames = deviceConfig[VAR_NAME_SERIAL_NUMBER] as
			| string[]
			| undefined;
		const snRendered = renderFields(
			zephyrFieldNames,
			redcapFields,
			'serial number'
		);

		const appliedRendered = redcapSensorAppliedTime(
			redcapFields,
			VAR_NAME_SENSOR_APPLIED,
			studyConfig,
			deviceConfig
		);
		const removalRendered = redcapSensorRemovalTime(
			redcapFields,
			VAR_NAME_SENSOR_REMOVAL,
			studyConfig,
			deviceConfig
		);
		return (
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute={`Serial Number(s)`} value={snRendered} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Assigned" value={appliedRendered} />
				</Grid>
				<Grid item xs={12} sm={6} />
				<Grid item xs={12} sm={6}>
					<DeviceDetailItem attribute="Date Removed" value={removalRendered} />
				</Grid>
			</Grid>
		);
	},
};

export default ZephyrDeviceRenderStrategy;
